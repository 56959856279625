<ul class="list-group-inset">
  <li class="is-clickable">
    <a class="stretched-link nav-item-account" [appTrackingNav]="'Menu Contact (mijn account)'" [href]="magentaAccountUrl">
      <div class="flex align-items-center" style="max-height: 80px">
        <span class="icon-arrow-left mr-1"></span>

        <div class="avatar avatar-white glow-static mr-2">{{ customerInitials }}</div>

        <span class="user-header">{{ 'my-account' | content}}</span>
      </div>
    </a>
  </li>
</ul>
