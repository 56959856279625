import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private readonly authService: AuthService) {}

  canActivate(_route: ActivatedRouteSnapshot): boolean {
    return this.canActivateInternal();
  }

  canActivateChild(_route: ActivatedRouteSnapshot): boolean {
    return this.canActivateInternal();
  }

  private canActivateInternal(): boolean {
    if (this.authService.isLoggedIn()) {
      return true;
    }

    this.authService.startAuthentication();
    return false;
  }
}
