import { AfterViewInit, Component, Input } from '@angular/core';
import { NavigationCategory, NavigationLink } from 'app/core/navigation/models/navigation-link';

declare let window: any;

@Component({
  selector: 'app-navigation-view',
  templateUrl: './navigation-view.component.html',
  styleUrls: ['./navigation-view.component.scss']
})
export class NavigationViewComponent implements AfterViewInit {
  private _links: NavigationCategory[];

  get links(): NavigationCategory[] {
    return this._links;
  }

  @Input() set links(links: NavigationCategory[]) {
    this._links = links;

    this.mainMenuLinks = links?.filter(cat => cat.menuLevel === 0)[0]?.links?.filter(link => link?.visible === true) ?? [];
    this.modalMenuLinks = links?.filter(cat => cat.menuLevel === 1) ?? [];
    this.modalSubMenuLinks = links?.filter(cat => cat.menuLevel === 2) ?? [];

    this.modalMenuTitle = this.mainMenuLinks.filter(link => !link.url)[0]?.text ?? '';

    let subNavLinks: NavigationLink[] = [];
    this.modalMenuLinks.forEach(c => {
      subNavLinks = subNavLinks.concat(c.links.filter(link => !!link && !link.url));
    });

    this.modalSubMenuTitle = subNavLinks[0]?.text ?? '';
  }

  mainMenuLinks: NavigationLink[];
  modalMenuLinks: NavigationCategory[];
  modalMenuTitle: string;
  modalSubMenuLinks: NavigationCategory[];
  modalSubMenuTitle: string;

  ngAfterViewInit(): void {
    window.TMobileDesignSystem.initSubNav();
  }
}
