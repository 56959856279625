import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ViaplayCustomerModel } from 'app/shared/open-api/data-contracts';
import { Observable } from 'rxjs';
import { Endpoints } from '../Endpoints';

@Injectable()
export class EntertainmentService {
  constructor(private readonly httpClient: HttpClient) { }

  viaplayCustomer(): Observable<ViaplayCustomerModel | null> {
    return this.httpClient.get<ViaplayCustomerModel | null>(Endpoints.entertainmentViaplay);
  }
}
