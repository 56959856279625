import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AuthConfig, OAuthInfoEvent, OAuthService } from 'angular-oauth2-oidc';
import { LoggingService } from 'app/core/logging.service';
import { AccountService } from 'app/core/services/account.service';
import { UrlService } from 'app/core/services/url.service';
import { ApplicationSettings } from 'app/shared/models/ApplicationSettings.model';
import { CustomerInformationViewModel } from 'app/shared/open-api/data-contracts';
import { SessionStorage } from 'app/shared/storage/sessionstorage.service';
import { TrackingService } from 'app/shared/tracking.service';
import { UserInformationModel } from 'app/shared/userinformation.models';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { filter, first, takeWhile, tap } from 'rxjs/operators';

declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  applicationSettings: ApplicationSettings | null = null;

  customerInformation: UserInformationModel | null = null;

  keepAliveTriggered = new ReplaySubject<string>(1);
  userLoaded = new BehaviorSubject<UserInformationModel | null>(null);

  constructor(
    private readonly accountService: AccountService,
    private readonly loggingService: LoggingService,
    private readonly oauthService: OAuthService,
    private readonly trackingService: TrackingService,
    private readonly sessionStorage: SessionStorage,
    private readonly router: Router,
    private readonly urlService: UrlService
  ) {}

  async completeAuthentication() {
    return this.oauthService
      .tryLogin()
      .catch(() => {
        this.startLogout();
      })
      .then(async (success) => {
        if (success) {
          if (window.localStorage.getItem('session_ended') === '1') {
            window.localStorage.removeItem('session_ended');
          }

          window.onstorage = () => {
            if (window.localStorage.getItem('session_ended') === '1') {
              this.startLogout(false);
              window.onstorage = null;
            }
          };

          await this.processUser(true);

          if (!this.oauthService.state) {
            this.router.navigate(['/'], { replaceUrl: true });
            return;
          }

          const decoded = decodeURIComponent(this.oauthService.state as string);
          if (localStorage.getItem('nav_off') === '1') {
            (window as any).navigationHidden = true;
            const urlTree: UrlTree = this.router.createUrlTree([decoded], { queryParams: { nav: 'off' }});
            this.router.navigateByUrl(urlTree, { replaceUrl: true });
          } else {
            this.router.navigate([decoded], { replaceUrl: true });
          }

        } else {
          this.startLogout();
        }
      });
  }

  getUserName(): string {
    const identityClaims = this.oauthService.getIdentityClaims();
    if (identityClaims && identityClaims.sub && identityClaims.sub.includes('@')) {
      return identityClaims.sub;
    } else {
      return '';
    }
  }

  async initialize(authConfig: AuthConfig, globalSettings: ApplicationSettings): Promise<void> {
    this.oauthService.configure(authConfig);
    this.oauthService.setupAutomaticSilentRefresh();

    this.applicationSettings = globalSettings;

    await this.oauthService.loadDiscoveryDocument().catch((err) => {
      // Events, network errors are logged by different mechanisms. Here we only want to check rejection of the promise.
      if (typeof err === 'string' || err instanceof String) {
        this.loggingService.error(this, err.toString(), null);
      }
    });

    this.wireUpEvents();

    if (this.isLoggedIn()) {
      await this.processUser();
    }
  }

  isLoggedIn(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  startAuthentication() {
    const acrValues = this.getAcrValues();

    if (acrValues) {
      this.oauthService.customQueryParams = acrValues;
    }

    const exclusions = ['auth-callback', 'agentlogin'];
    const isExcluded = exclusions.some((e) => !!window.location.pathname && window.location.pathname.indexOf(e) !== -1);
    const state = isExcluded ? '/' : window.location.pathname;

    if (window.location.search?.includes('nav=off')) {
      localStorage.setItem('nav_off', '1')
    }

    this.oauthService.initCodeFlow(state);
  }

  startLogout(isInitiator: boolean = true): boolean {
    if (isInitiator) {
      window.onstorage = null;
      window.localStorage.setItem('session_ended', '1');
    }

    if (this.sessionStorage.get('CustomerCode') != null) {
      window.close();
      return false;
    }

    if (!this.isLoggedIn()) {
      this.urlService.setLocationHref(this.oauthService.postLogoutRedirectUri || window.location.origin + '/');
    } else {
      this.logout();
    }

    return true;
  }

  startLogoutAfterPrimaryEmailChange(url: string, email: string): Promise<any> {
    return this.logout(`${window.location.origin}${url}?email=${encodeURIComponent(email)}`);
  }

  private getAcrValues(): Record<string, string> | null {
    const customerCode = this.sessionStorage.get('CustomerCode');
    let signinRequestValues = {};

    if (customerCode != null) {
      signinRequestValues = { acr_values: `customerid:${customerCode}` };
    }

    return signinRequestValues;
  }

  private logout(postLogoutRedirectUri: string | null = null): Promise<any> {
    if (this.sessionStorage.get('CustomerCode')) {
      this.sessionStorage.remove('CustomerCode');
    }
    if (this.sessionStorage.get('IsAgent')) {
      this.sessionStorage.remove('IsAgent');
    }

    if (postLogoutRedirectUri) {
      this.oauthService.postLogoutRedirectUri = postLogoutRedirectUri;
    }

    return this.oauthService.revokeTokenAndLogout().then(() => {
      this.trackingService.logoutSuccessEvent();
    });
  }

  private async processUser(track: boolean = false): Promise<UserInformationModel | null> {
    if (this.oauthService.hasValidAccessToken()) {
      const accountInformation = await this.accountService
        .getAccountDetails()
        .pipe(
          first(),
          tap((account) => {
            if (track) {
              this.trackingService.loginSuccessEvent(account, account.isAgent === true);
            }
          })
        )
        .toPromise()
        .catch((rejected) => {
          if (rejected instanceof HttpErrorResponse && rejected.status === 503) {
            return {} as CustomerInformationViewModel;
          }

          setTimeout(() => {
            throw rejected;
          });
        });

      let userInformationModel = null;
      if (accountInformation) {
        userInformationModel = {
          customerId: accountInformation.customerId,
          isAgent: accountInformation.isAgent,
          noConvergedInfoYet: accountInformation.noConvergedInfoYet,
          isAuthenticatedAtMagenta: accountInformation.isAuthenticatedAtMagenta,
          magentaAlias: accountInformation.magentaAlias,
          magentaSubscriptions: accountInformation.magentaSubscriptions,
          magentaVkfe: accountInformation.magentaVkfe
        } as UserInformationModel;
      }

      this.customerInformation = userInformationModel || null;
      this.userLoaded.next(this.customerInformation);

      return this.customerInformation || null;
    }

    return Promise.resolve({} as UserInformationModel);
  }

  private refreshAtMagenta() {
    // Externally authenticated = magenta
    if (this.applicationSettings?.magentaSSOKeepAliveEndpoint && this.customerInformation?.isAuthenticatedAtMagenta) {
      this.keepAliveTriggered.next(this.applicationSettings.magentaSSOKeepAliveEndpoint);
    }
  }

  private wireUpEvents() {
    this.userLoaded.pipe(takeWhile((n, _) => n == null, true)).subscribe((val) => {
      if (val) {
        // Sync up the sessions on startup (should be close enough to our token), we need the user before we can do that
        this.refreshAtMagenta();
      }
    });

    this.oauthService.events
      .pipe(filter((e) => e.type === 'token_expires' && (e as OAuthInfoEvent)?.info === 'access_token'))
      .subscribe(() => {
        const acrValues = this.getAcrValues();

        if (acrValues) {
          this.oauthService.customQueryParams = acrValues;
        }

        // Sync up the sessions at 75% expiry on our token
        this.refreshAtMagenta();
      });
  }
}
