import { Injectable } from '@angular/core';
import numeral from 'numeral';
import 'numeral/locales/nl-nl';

/**
 * Use http://numeraljs.com in stead of Ng's Intl-API dependent implementation,
 * because we still care about Safari < 10 and IE < 11(https://angular.io/guide/browser-support)
 */
@Injectable()
export class NumeralService {
  constructor() {
    this.initialize();
  }

  format(value: string, format: string) {
    return numeral(value).format(format);
  }

  private initialize() {
    numeral.locale('nl-NL');
  }
}
