import { Directive, HostListener } from '@angular/core';
import { TrackingService } from '../tracking.service';

/** A directive to track clicks */
@Directive()
export abstract class TrackingDirective {
  trackingCategory: string;
  trackingLabel: string;

  constructor(protected readonly trackingService: TrackingService) { }

  @HostListener('click')
  onClick(): void {
    this.trackingService.clickEvent(this.trackingCategory, this.trackingLabel);
  }
}
