import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConvergedStatusViewModel } from 'app/shared/open-api/data-contracts';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Endpoints } from '../Endpoints';

@Injectable()
export class ConvergedService {
  constructor(private readonly httpClient: HttpClient) {}

  customerIsConvergedV2(): Observable<ConvergedStatusViewModel | null> {
    return this.httpClient.get<ConvergedStatusViewModel>(Endpoints.converged).pipe(first());
  }
}
