import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ViaplayCustomerModel } from 'app/shared/open-api/data-contracts';
import { first } from 'rxjs/operators';
import { EntertainmentService } from '../services/entertainment.service';

@Injectable()
export class EntertainmentViaplayResolver implements Resolve<ViaplayCustomerModel | null> {
  constructor(private entertainmentService: EntertainmentService) { }

  resolve() {
    return this.entertainmentService.viaplayCustomer().pipe(first());
  }
}
