import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrderTypes } from 'app/shared/models/order/types/ordertype.enum';
import { ProductNames } from 'app/shared/models/productnames.model';
import {
  Address, InitialOrderDetailsViewModel, OrderListViewModel, OrderResultModel, ProductOrderHistoryModel,
  ProductOrderHistoryParametersModel
} from 'app/shared/open-api/data-contracts';
import { TrackingEventInfoModel } from 'app/shared/tracking.models';
import { TrackingService } from 'app/shared/tracking.service';
import { map, Observable } from 'rxjs';
import { Endpoints } from '../Endpoints';

@Injectable()
export class OrderService {
  readonly pageSize = 10;

  constructor(private readonly httpClient: HttpClient, private readonly trackingService: TrackingService) { }

  getInitialOrder(): Observable<InitialOrderDetailsViewModel | null> {
    return this.httpClient.get<InitialOrderDetailsViewModel>(Endpoints.ordersRouteInitial);
  }

  getOrderDetails<T>(orderId: string): Observable<T | null> {
    return this.httpClient.get<T>(Endpoints.ordersRouteDetails.format(orderId));
  }

  getOrdersCompleted(pageNo: number, pageSize: number): Observable<OrderListViewModel | null> {
    return this.httpClient.get<OrderListViewModel>(Endpoints.ordersRouteCompleted.format(pageNo.toString(), pageSize.toString()))
      // workaround OIS -> not detecting OVO orders as modify  
      .pipe(map((orderList: OrderListViewModel) => {
        const ovoOrders = orderList.items?.
          filter(i => i.products?.some(p => p.productName === ProductNames.overalVeiligOnline) &&
            i.products.length === 1 &&
            [OrderTypes.ProductAdd, OrderTypes.ProductDelete].includes(i.orderType ?? OrderTypes.Unknown)
          );
        ovoOrders?.forEach(o => o.orderType = OrderTypes.ProductChange);
        return orderList;
      }));
  }

  getOrdersOpen(): Observable<OrderListViewModel | null> {
    return this.httpClient.get<OrderListViewModel>(Endpoints.ordersRouteOpenAll)
      // workaround OIS -> not detecting OVO orders as modify  
      .pipe(map((orderList: OrderListViewModel) => {
        const ovoOrders = orderList.items?.
          filter(i => i.products?.some(p => p.productName === ProductNames.overalVeiligOnline) &&
            i.products.length === 1 &&
            [OrderTypes.ProductAdd, OrderTypes.ProductDelete].includes(i.orderType ?? OrderTypes.Unknown)
          );
        ovoOrders?.forEach(o => o.orderType = OrderTypes.ProductChange);
        return orderList;
      }));
  }

  getProductOrderHistory(params: ProductOrderHistoryParametersModel): Observable<ProductOrderHistoryModel[] | null> {
    return this.httpClient.get<ProductOrderHistoryModel[]>(Endpoints.ordersRouteProductOrderHistory, { params: params as any });
  }

  isAnyOrderOpen(): Observable<boolean> {
    return this.httpClient.get<boolean>(Endpoints.ordersRouteAnyOpen);
  }

  saveShippingAddress(orderId: string, shippingAddress: Address): Observable<OrderResultModel> {
    const model = { orderId, shippingAddress };

    const trackingInfo = {
      trackingCategory: 'SS - Bezorgadres wijzigen',
      trackingLabel: 'Bezorgadres wijzigen'
    } as TrackingEventInfoModel;

    return this.trackingService.trackedPost<OrderResultModel>(Endpoints.ordersRouteShippingAddress, model, trackingInfo);
  }
}
