import { Component, OnInit } from '@angular/core';
import { LoadingIndicatorService } from 'app/core/loading-indicator.service';

@Component({
  templateUrl: './notfound.component.html'
})
export class NotFoundComponent implements OnInit {
  description = 'De pagina die je zoekt bestaat niet. Of niet meer. Of er zit een foutje in de link.';
  header = 'Sorry! Wij kunnen de pagina niet vinden.';

  constructor(
    private readonly loadingIndicatorService: LoadingIndicatorService
  ) {
  }

  ngOnInit(): void {
    this.loadingIndicatorService.setPageLoadComplete();
  }
}
