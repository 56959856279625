import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Address,
  CeaseProductCommand,
  OrderResultModel,
  SetTopBoxesInfoModel,
  SubscriptionOwnedProductModel,
  TvOrderCommand,
  TvProductsModel,
  TvSetTopBoxProductsModel
} from 'app/shared/open-api/data-contracts';
import { TrackingEventInfoModel } from 'app/shared/tracking.models';
import { TrackingService } from 'app/shared/tracking.service';
import { Observable, of } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';
import { Endpoints } from '../Endpoints';

@Injectable()
export class TvProductsService {
  constructor(private readonly httpClient: HttpClient, private readonly trackingService: TrackingService) {}

  ceaseTv(product: SubscriptionOwnedProductModel, hasStbs: boolean): Observable<boolean> {
    const trackingInfo = {
      trackingCategory: 'SS - TV uit',
      trackingLabel: `TV uit ${hasStbs ? 'met' : 'zonder'} TV-Box`
    } as TrackingEventInfoModel;

    return this.trackingService
      .trackedPost<OrderResultModel>(
      Endpoints.productsCease,
      {
        products: [
          {
            name: product.name,
            technicalId: product.technicalId
          } as CeaseProductCommand
        ]
      },
      trackingInfo
    )
      .pipe(concatMap(() => of(true)));
  }

  getAvailableTvProducts(
    postalCode: string | null = null,
    houseNumber: number | null = null,
    houseNumberAddition: string | null = null
  ): Observable<TvProductsModel> {
    let route = Endpoints.productsTv;
    if (postalCode && houseNumber) {
      route = Endpoints.productsTvByAddress.format(postalCode, houseNumber.toString(), houseNumberAddition);
    }
    return this.httpClient.get<TvProductsModel>(route).pipe(first());
  }

  getSetTopBoxesInfo(): Observable<SetTopBoxesInfoModel | null> {
    return this.httpClient.get<SetTopBoxesInfoModel>(Endpoints.setTopBoxesInfo).pipe(first());
  }

  orderTv(product: TvSetTopBoxProductsModel, shippingAddress: Address | undefined): Observable<boolean> {
    const trackingInfo = {
      trackingCategory: shippingAddress ? 'SS - TV aan - Afwijkend Bezorgadres' : 'SS - TV aan',
      trackingLabel: 'TV aan'
    } as TrackingEventInfoModel;

    const model = {
      stbCount: product.setTopBoxCount,
      shippingAddress
    } as TvOrderCommand;

    return this.trackingService.trackedPost(Endpoints.productsTv, model, trackingInfo);
  }
}
