import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CloudRecordingModel, OrderResultModel } from 'app/shared/open-api/data-contracts';
import { TrackingEventInfoModel } from 'app/shared/tracking.models';
import { TrackingService } from 'app/shared/tracking.service';
import { Observable } from 'rxjs';
import { Endpoints } from '../Endpoints';

@Injectable()
export class TelevisionService {
  constructor(private readonly httpClient: HttpClient, private readonly trackingService: TrackingService) {}

  getAvailableCloudRecordingProducts(): Observable<CloudRecordingModel | null> {
    return this.httpClient.get<CloudRecordingModel>(Endpoints.productRecording);
  }

  /**
   * Sends a request to disable local recording and enable cloud recording depending on the value
   * @param disableLocalRecording Whether or not to disable local recording (LPVR)
   * @param enableCloudRecording whether or not to enable cloud recording (NPVR)
   */
  orderCloudRecording(disableLocalRecording: boolean, enableCloudRecording: boolean | null): Observable<OrderResultModel> {
    const trackingInfo = {
      trackingCategory: !!enableCloudRecording ? 'SS - Cloud Opnemen aan' : 'SS - Cloud Opnemen uit',
      trackingLabel: !!enableCloudRecording ? 'Cloud Opnemen aan' : 'Cloud Opnemen uit'
    } as TrackingEventInfoModel;

    const model = {
      disableLocalRecording,
      enableCloudRecording
    };

    return this.trackingService.trackedPost<OrderResultModel>(Endpoints.productRecording, model, trackingInfo);
  }
}
