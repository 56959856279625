import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApplyVoucherContentCommandModel,
  ApplyVoucherContentResultviewModel,
  UnapplyVoucherContentCommandModel,
  UnapplyVoucherContentResultViewModel
} from 'app/shared/open-api/data-contracts';
import { Observable } from 'rxjs';
import { Endpoints } from '../Endpoints';

@Injectable()
export class VoucherService {
  constructor(private readonly http: HttpClient) {}

  applyVoucher(commandModel: ApplyVoucherContentCommandModel): Observable<ApplyVoucherContentResultviewModel> {
    return this.http.post<ApplyVoucherContentResultviewModel>(Endpoints.voucherApply, commandModel);
  }

  unApplyVoucher(commandModel: UnapplyVoucherContentCommandModel): Observable<UnapplyVoucherContentResultViewModel> {
    return this.http.post<UnapplyVoucherContentResultViewModel>(Endpoints.voucherUnApply, commandModel);
  }
}
