import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DunningStatusModel, InvoiceModel, OpenBalanceModel } from 'app/shared/open-api/data-contracts';
import { TrackingEventInfoModel } from 'app/shared/tracking.models';
import { TrackingService } from 'app/shared/tracking.service';
import { FileSaverService } from 'ngx-filesaver';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Endpoints } from '../Endpoints';

@Injectable()
export class InvoicesService {
  private pdfTrackingInfo = {
    trackingCategory: 'SS - Download',
    trackingLabel: 'Rekening'
  } as TrackingEventInfoModel;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly fileSaverService: FileSaverService,
    private readonly trackingService: TrackingService
  ) {}

  getDunningStatus(): Observable<DunningStatusModel | null> {
    return this.httpClient.get<DunningStatusModel>(Endpoints.invoicesDunningStatus);
  }

  getInvoicePdf(invoiceId: number): Observable<any> {
    return this.trackingService
      .trackedGetAsBlob(Endpoints.invoicesSpecificPdf.format(invoiceId.toString()), this.pdfTrackingInfo)
      .pipe(map((res) => this.fileSaverService.save(res, `TMTrekening-${invoiceId}.pdf`)));
  }

  getInvoicePdfs(invoiceIds: number[]): Observable<any> {
    let params = new HttpParams();
    invoiceIds.forEach((iid) => {
      params = params.append('invoiceIds', iid.toString());
    });

    return this.trackingService
      .trackedGetAsBlob(Endpoints.invoicesPdf, this.pdfTrackingInfo, params)
      .pipe(map((res) => this.fileSaverService.save(res, 'T-Mobile Thuis Rekeningen.zip')));
  }

  getInvoices(): Observable<InvoiceModel[] | null> {
    return this.httpClient.get<InvoiceModel[]>(Endpoints.invoices);
  }

  getOpenBalance(): Observable<OpenBalanceModel | null> {
    return this.httpClient.get<OpenBalanceModel>(Endpoints.invoicesOpenBalance);
  }
}
