import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ProductsService } from 'app/core/services/products.service';
import { SubscriptionModel } from 'app/shared/open-api/data-contracts';
import { Observable } from 'rxjs';

@Injectable()
export class SubscriptionResolver implements Resolve<SubscriptionModel> {
  constructor(private productService: ProductsService) {}

  resolve(): Observable<SubscriptionModel> {
    return this.productService.getSubscriptionForCustomer();
  }
}
