import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { SubscriptionStatusEnum } from 'app/shared/models/subscription/subscription-status.enum';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { SubscriptionService } from '../services/subscription.service';

@Injectable()
export class SubscriptionStatusResolver implements Resolve<SubscriptionStatusEnum | null> {
  constructor(private subscriptionService: SubscriptionService) {}

  resolve(): Observable<SubscriptionStatusEnum | null> {
    return this.subscriptionService.getSubscriptionStatus().pipe(first());
  }
}
