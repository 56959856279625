import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { genericRetryStrategy } from 'app/shared/rxjs/genericretrystrategy';
import { empty } from 'rxjs';
import { catchError, first, retryWhen } from 'rxjs/operators';
import { Endpoints } from './Endpoints';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  constructor(private http: HttpClient) {}

  debug(sender: any, message: string, ...args: any[]) {
    this.logToConsole(sender, message, LogLevel.Debug, args);
  }

  error(sender: any, message: string, url: string | null, stackTrace?: string, ...args: any[]) {
    this.logToConsole(sender, message, LogLevel.Error, args);

    const source = sender ? sender.constructor.name : '';
    this.logToApi(source, message, LogLevel.Error, url, stackTrace);
  }

  info(sender: any, message: string, ...args: any[]) {
    this.logToConsole(sender, message, LogLevel.Info, args);
  }

  warn(sender: any, message: string, url?: string | null, ...args: any[]) {
    this.logToConsole(sender, message, LogLevel.Warning, args);

    const source = sender ? sender.constructor.name : '';
    this.logToApi(source, message, LogLevel.Warning, url);
  }

  warnWithStackTrace(sender: any, message: string, url: string | null, stackTrace?: string, ...args: any[]) {
    this.logToConsole(sender, message, LogLevel.Warning, args);

    const source = sender ? sender.constructor.name : '';
    this.logToApi(source, message, LogLevel.Warning, url, stackTrace);
  }

  private logToApi(source: string, message: string, logLevel: LogLevel, url: string | null | undefined, stackTrace?: string) {
    // There's no point in logging and retrying when the error occurs in the /api/log route.
    // That is only clutter, we need to make sure it works.
    if (!url || url.indexOf(Endpoints.log) === -1) {
      const body = {
        source: source ? source : null,
        message: message ? message : null,
        url: url ? url : null,
        stackTrace: stackTrace ? stackTrace : null,
        level: logLevel
      };

      this.http
        .post<void>(Endpoints.log, body)
        // Errors are retried 10 times with increasing duration (1s, 2s, 3s, 4s, 5s, etc) and the 401 status is excluded
        .pipe(retryWhen(genericRetryStrategy({ excludedStatusCodes: [401], maxRetryAttempts: 9 })))
        .pipe(
          first(),
          // There is no fallback scenario here.
          catchError(() => empty())
        )
        .subscribe();
    }
  }

  private logToConsole(sender: any, message: string, level: LogLevel, ...args: any[]) {
    if (localStorage.getItem('debug') !== 'true') {
      return;
    }

    const logmessage = sender ? `[${sender.constructor.name}]: ${message}` : message;

    switch (level) {
      case LogLevel.Warning:
        console.warn(logmessage, args);
        break;

      case LogLevel.Error:
        console.error(logmessage, args);
        break;

      default:
        console.log(logmessage, args);
    }
  }
}

enum LogLevel {
  Debug = 1,
  Info = 2,
  Warning = 3,
  Error = 4
}
