import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Address, SubscribeToHouseholdModel, ToAllesOnder1DakDashboardViewModel } from 'app/shared/open-api/data-contracts';
import { TrackingEventInfoModel } from 'app/shared/tracking.models';
import { TrackingService } from 'app/shared/tracking.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Endpoints } from '../Endpoints';

@Injectable()
export class HouseholdService {
  constructor(private readonly httpClient: HttpClient, private readonly trackingService: TrackingService) {}

  getHouseHoldDashboardUri(): Observable<ToAllesOnder1DakDashboardViewModel | null> {
    return this.httpClient.get<ToAllesOnder1DakDashboardViewModel>(Endpoints.householdUri).pipe(map((hh) => (hh ? hh : null)));
  }

  getHouseholdSubscribeAddress(): Observable<Address | null> {
    return this.httpClient
      .get<Address>(Endpoints.householdSubscribeAddress)
      .pipe(map((response) => (response ? response : null)));
  }

  mayJoinHousehold(): Observable<boolean> {
    return this.httpClient.get<boolean>(Endpoints.householdMayJoin).pipe(map((r) => !!r && r));
  }

  subscribeToHousehold(model: SubscribeToHouseholdModel | null = null): Observable<any> {
    if (model === null) {
      model = {} as SubscribeToHouseholdModel;
    }

    const trackingInfo = {
      trackingCategory: 'SS - Aanmelden Klantvoordeel',
      trackingLabel: 'Aanmelden Klantvoordeel'
    } as TrackingEventInfoModel;

    return this.trackingService.trackedPost<any>(Endpoints.householdSubscribe, model, trackingInfo);
  }

  unsubscribeFromHousehold(): Observable<any> {
    return this.httpClient.post<any>(Endpoints.householdUnsubscribe, {});
  }
}
