/* eslint-disable @typescript-eslint/member-ordering */
export class ProductNames {
  static espnCompleet = 'Fox Sports Compleet';
  static espnEredivisie = 'Fox Sports Eredivisie';
  static ziggoSport = 'Ziggo Sport Totaal';
  static film1 = 'Film1';
  static adult = 'Erotiek pakket';
  static entertainment = 'Entertainment';
  static infotainment = 'Infotainment';
  static international = 'International';
  static kidsAndMusic = 'Kids & Music';

  static tv = 'Interactieve TV';
  static tvCloudRecording = 'TV Cloud recording';
  static numberPorting = 'Nummerbehoud';
  static viaplay = 'Viaplay';
  static sportsbundle = 'Viaplay + ESPN Compleet';

  static thuisVeiligOnline = 'Thuis Veilig Online';
  static overalVeiligOnline = 'Overal Veilig Online';
  static thuisOveralVeiligOnline = 'Thuis + Overal Veilig Online'
  static overalVeiligOnline3Apparaten = 'Overal Veilig Online voor 3 Apparaten';
  static overalVeiligOnline10Apparaten = 'Overal Veilig Online voor 10 Apparaten';
  static overalVeiligOnline25Apparaten = 'Overal Veilig Online voor 25 Apparaten';
}
