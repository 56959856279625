import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Address } from 'app/shared/open-api/data-contracts';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { HouseholdService } from '../services/household.service';

@Injectable()
export class HouseHoldSubscribeAddressResolver implements Resolve<Address | null> {
  constructor(private houseHoldService: HouseholdService) {}

  resolve(): Observable<Address | null> {
    return this.houseHoldService.getHouseholdSubscribeAddress().pipe(first());
  }
}
