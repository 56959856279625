import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OpenSupportRequestModel } from 'app/shared/open-api/data-contracts';
import { TrackingEventInfoModel } from 'app/shared/tracking.models';
import { TrackingService } from 'app/shared/tracking.service';
import { Observable, of } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';
import { Endpoints } from '../Endpoints';

@Injectable()
export class RemoteMechanicService {
  constructor(private readonly httpClient: HttpClient, private trackingService: TrackingService) {}

  createRemoteMechanic(): Observable<any> {
    const trackingInfo = {
      trackingCategory: 'SS - Remote mechanic aanvragen',
      trackingLabel: 'Monteur op afstand aangevraagd'
    } as TrackingEventInfoModel;

    return this.trackingService.trackedPost<any>(Endpoints.remoteMechanic, {}, trackingInfo).pipe(concatMap(() => of(true)));
  }

  openSupportRequests(): Observable<OpenSupportRequestModel | null> {
    return this.httpClient.get<OpenSupportRequestModel>(Endpoints.remoteMechanicOpenSupportRequests).pipe(first());
  }
}
