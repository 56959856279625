import hash from 'hash.js';

export class HashingService {
  static createDcfHash(source: string | number | null | undefined): string | null {
    if (source === null || source === undefined) {
      return null;
    }

    const strToHash = source.toString().replace(/[ ]/g, '').toLowerCase();
    return hash.sha256().update(strToHash).digest('hex');
  }
}    
