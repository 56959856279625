import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { HasGdprDataModel } from 'app/shared/open-api/data-contracts';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { GdprService } from '../services/gdpr.service';

@Injectable()
export class HasGdprDataResolver implements Resolve<HasGdprDataModel> {
  constructor(private gdprService: GdprService) {}

  resolve(): Observable<HasGdprDataModel> {
    return this.gdprService.hasGdprData().pipe(first());
  }
}
