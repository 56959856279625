import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { CustomerInformationViewModel } from 'app/shared/open-api/data-contracts';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { AccountService } from '../services/account.service';

@Injectable()
export class AccountResolver implements Resolve<CustomerInformationViewModel> {
  constructor(private accountService: AccountService) {}

  resolve(): Observable<CustomerInformationViewModel> {
    return this.accountService.getAccountDetails().pipe(first());
  }
}
