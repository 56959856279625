import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Endpoints } from './Endpoints';

class CacheItem {
  expiration: number;
  response: HttpResponse<any> | null;
}

class CacheConfiguration {
  constructor(readonly get: string, readonly ttl: number, readonly posts: string[] | null = null) {}
}

/** A global cache containing the cached HTTP requests */
@Injectable({ providedIn: 'root' })
export class RequestCache {
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private cache: Map<string, CacheItem> = new Map();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private cacheConfiguration: CacheConfiguration[] = [
    // Account
    new CacheConfiguration(Endpoints.account, 300, [
      Endpoints.accountSetPhoneNumbers,
      Endpoints.accountPrimaryEmail,
      Endpoints.accountPrivacy
    ]),
    new CacheConfiguration(Endpoints.accountBasic, 300),
    new CacheConfiguration(Endpoints.accountContractInfo, 300),
    new CacheConfiguration(Endpoints.accountPrivacy, 300, [Endpoints.accountPrivacy]),
    new CacheConfiguration(Endpoints.accountPaymentInformation, 300, [Endpoints.accountPaymentInformation]),

    // ChangeOffer
    new CacheConfiguration(Endpoints.changeOfferNetworkMigration, 300),

    // Converged
    new CacheConfiguration(Endpoints.converged, 300, [Endpoints.householdSubscribe, Endpoints.householdUnsubscribe]),

    // Credits
    new CacheConfiguration(Endpoints.credits, 300),

    // entertainment
    new CacheConfiguration(Endpoints.entertainmentViaplay, 300, [
      Endpoints.productInternet,
      Endpoints.productsAdd,
      Endpoints.productsCease,
      Endpoints.move
    ]),

    // Features
    new CacheConfiguration(Endpoints.features, 300),

    // Household
    new CacheConfiguration(Endpoints.householdMayJoin, 300),

    // Installmentplan
    new CacheConfiguration(Endpoints.installmentplan, 30, [Endpoints.installmentplan]),
    new CacheConfiguration(Endpoints.installmentplanEligibility, 30, [Endpoints.installmentplan]),

    // invoices
    new CacheConfiguration(Endpoints.invoices, 300),
    new CacheConfiguration(Endpoints.invoicesDunningStatus, 300),
    new CacheConfiguration(Endpoints.invoicesOpenBalance, 300),

    // move
    new CacheConfiguration(Endpoints.moveToAddress, 300),

    // opt out
    new CacheConfiguration(Endpoints.optOutV2, 300, [Endpoints.optOutPostV2]),

    // orders
    new CacheConfiguration(Endpoints.ordersRouteInitial, 30),
    new CacheConfiguration(Endpoints.ordersRouteDetails, 30),
    new CacheConfiguration(Endpoints.ordersRouteAnyOpen, 30, [
      Endpoints.productInternet,
      Endpoints.productsAdd,
      Endpoints.productsModify,
      Endpoints.productsCease,
      Endpoints.productsVoip,
      Endpoints.productNetworkMigration,
      Endpoints.productNetworkMigrationPreOrder,
      Endpoints.productRecording,
      Endpoints.productsTv,
      Endpoints.move
    ]),
    new CacheConfiguration(Endpoints.ordersRouteOpenAll, 30, [
      Endpoints.productInternet,
      Endpoints.productsAdd,
      Endpoints.productsModify,
      Endpoints.productsCease,
      Endpoints.productsVoip,
      Endpoints.productNetworkMigration,
      Endpoints.productNetworkMigrationPreOrder,
      Endpoints.productRecording,
      Endpoints.productsTv,
      Endpoints.move
    ]),
    // this should not be refreshed after a  placement, an order is not immediately completed :)
    new CacheConfiguration(Endpoints.ordersRouteCompleted, 30),
    new CacheConfiguration(Endpoints.ordersRouteProductOrderHistory, 30),

    // Outage
    new CacheConfiguration(Endpoints.outageIncidents, 300),

    // postponement
    new CacheConfiguration(Endpoints.postponepayment, 30, [Endpoints.postponepayment]),

    // products
    new CacheConfiguration(Endpoints.productInternet, 30, [
      Endpoints.productsModify,
      Endpoints.productNetworkMigration,
      Endpoints.productNetworkMigrationPreOrder
    ]),
    new CacheConfiguration(Endpoints.productRecording, 30, [Endpoints.productRecording]),
    new CacheConfiguration(Endpoints.productsTv, 30, [Endpoints.productsTv]),
    new CacheConfiguration(Endpoints.productsTvByAddress, 30, [Endpoints.productsTv]),
    new CacheConfiguration(Endpoints.productsVoip, 30, [Endpoints.productsVoip]),
    new CacheConfiguration(Endpoints.productsWifiExtender, 30, [Endpoints.productsAdd, Endpoints.productsCease]),

    // Remote mechanic
    new CacheConfiguration(Endpoints.remoteMechanicOpenSupportRequests, 30, [Endpoints.remoteMechanic]),

    // SetTopBoxes info
    new CacheConfiguration(Endpoints.setTopBoxesInfo, 3000),

    // Subscription
    new CacheConfiguration(Endpoints.subscription, 30, [
      Endpoints.productsAdd,
      Endpoints.productsModify,
      Endpoints.productsCease,
      Endpoints.productInternet,
      Endpoints.productsVoip,
      Endpoints.productNetworkMigration,
      Endpoints.productNetworkMigrationPreOrder,
      Endpoints.productRecording,
      Endpoints.productsTv,
      Endpoints.move,
      Endpoints.voucherApply,
      Endpoints.voucherUnApply
    ]),

    new CacheConfiguration(Endpoints.subscriptionStatus, 30, [
      Endpoints.move,
      Endpoints.productNetworkMigration,
      Endpoints.productNetworkMigrationPreOrder
    ]),

    // Transfer
    new CacheConfiguration(Endpoints.transfer, 300, [Endpoints.transferUpdate, Endpoints.transferUpdateByReference]),

    // tv settings
    new CacheConfiguration(Endpoints.tvSettings, 300),

    // Usage
    new CacheConfiguration(Endpoints.usageVod, 300),
    new CacheConfiguration(Endpoints.usageVoip, 300),

    // voip settings
    new CacheConfiguration(Endpoints.voipSettings, 300, [
      Endpoints.voipSettingsNumberBlocking,
      Endpoints.voipSettingsNumberPorting,
      Endpoints.voipSettingsVoicemail
    ]),
    new CacheConfiguration(Endpoints.voipCheckNetCode, 300)
  ];

  /** Completely clear the cache */
  clear(): void {
    this.cache = new Map();
  }

  /** Clear the cache for a specific set of URLs */
  clearFor(postUrl: string) {
    const dependants = this.cacheConfiguration.filter((cc) => cc.posts?.includes(postUrl));

    dependants.forEach((d) => {
      if (this.cache.delete(d.get)) {
        return;
      }

      if (d.get.includes('{')) {
        const searchingFor = d.get.split('/');

        this.cache.forEach((_, key) => {
          if (
            key.startsWith(searchingFor.filter((sf) => !sf.includes('{')).join('/')) &&
            key.split('/').length === searchingFor.length
          ) {
            this.cache.delete(key);
          }
        });
      }
    });
  }

  /** Retrieve or set result from or to the cache by URL */
  get(request: HttpRequest<any>): HttpResponse<any> | null {
    if (this.cache.has(request.url)) {
      const item = this.cache.get(request.url);

      if (item && moment(item.expiration) > moment(moment().unix())) {
        return item.response;
      } else {
        this.cache.delete(request.url);
      }
    }

    return null;
  }

  set(request: HttpRequest<any>, response: HttpResponse<any>): void {
    let cacheConfiguration = this.cacheConfiguration.find((cc) => cc.get === request.url);

    if (!cacheConfiguration) {
      const parameterized = this.cacheConfiguration.filter((cc) => cc.get.includes('{'));

      parameterized.forEach((p) => {
        const configured = p.get.split('?')[0].split('/');
        const startsWith = configured.filter((c) => !c.includes('{'));
        const splitRequest = request.url.split('/');
        const withoutParameters = splitRequest.slice(0, startsWith.length).join('/');

        if (splitRequest.length === configured.length && withoutParameters.startsWith(startsWith.join('/'))) {
          if (request.params.keys().length === 0) {
            cacheConfiguration = p;
          } else if (p.get.split('&').length === request.params.keys().length) {
            cacheConfiguration = p;
          }
        }
      });
    }

    if (cacheConfiguration) {
      const expiration = moment().add(cacheConfiguration.ttl, 'seconds').unix();

      this.cache.set(request.url, { response, expiration } as CacheItem);
    }
  }
}
