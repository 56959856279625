import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PostponePaymentCommand, PostponePaymentModel } from 'app/shared/open-api/data-contracts';
import { TrackingEventInfoModel } from 'app/shared/tracking.models';
import { TrackingService } from 'app/shared/tracking.service';
import { Observable, of } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';
import { Endpoints } from '../Endpoints';

@Injectable()
export class PostponePaymentService {
  constructor(private readonly httpCacheClient: HttpClient, private trackingService: TrackingService) {}

  createPostponePayment(model: PostponePaymentCommand): Observable<any> {
    const trackingInfo = {
      trackingCategory: 'SS - Uitstel betaling aanvragen',
      trackingLabel: 'Uitstel betaling aanvragen'
    } as TrackingEventInfoModel;

    return this.trackingService.trackedPost<any>(Endpoints.postponepayment, model, trackingInfo).pipe(concatMap(() => of(true)));
  }

  getPostponePayment(): Observable<PostponePaymentModel | null> {
    return this.httpCacheClient.get<PostponePaymentModel>(Endpoints.postponepayment).pipe(first());
  }
}
