import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { VeiligOnlineService } from 'app/modules/shop/internet/veilig-online/veilig-online.service';
import { Observable } from 'rxjs';

@Injectable()
export class FSecureResolver implements Resolve<any> {
  constructor(private veiligOnlineService: VeiligOnlineService) {}

  resolve(): Observable<any> {
    return this.veiligOnlineService.veiligOnlineCustomer();
  }
}
