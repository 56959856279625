import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { SessionStorage } from 'app/shared/storage/sessionstorage.service';
import { LoadingIndicatorService } from '../loading-indicator.service';
import { PageBase } from '../page-base';

@Component({
  selector: 'app-agentlogin',
  templateUrl: './agentlogin.component.html',
  styleUrls: ['./agentlogin.component.scss']
})
export class AgentLoginComponent extends PageBase {
  constructor(
    loadingIndicatorService: LoadingIndicatorService,
    private route: ActivatedRoute,
    private sessionStorage: SessionStorage,
    private authService: AuthService,
    private router: Router
  ) {
    super(loadingIndicatorService);

    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/']);
    } else {
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.sessionStorage.set('IsAgent', 'true');
        this.sessionStorage.set('CustomerCode', params.get('id') || '');
        this.authService.startAuthentication();
      });
    }
  }
}
