import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from 'app/core/Endpoints';
import { ProductsService } from 'app/core/services/products.service';
import { ProductNames } from 'app/shared/models/productnames.model';
import { FSecureCustomerModel, SubscriptionOwnedProductModel } from 'app/shared/open-api/data-contracts';
import { TrackingEventInfoModel } from 'app/shared/tracking.models';
import { Observable, of } from 'rxjs';
import { OveralVeiligOnlineChangeTypeEnum } from './veilig-online-model/overal-veilig-online-change-type.enum';

@Injectable({
  providedIn: 'root'
})
export class VeiligOnlineService {

  constructor(
    private readonly httpClient: HttpClient,
    private readonly productService: ProductsService
  ) { }

  addVeiligProducts(productName: string): Observable<boolean> {
    const trackingInfo = {
      trackingCategory: 'SS - Veilig Online aan',
      trackingLabel: productName === ProductNames.thuisVeiligOnline ? 'Thuis' : `Thuis + ${productName.replace('Veilig Online voor ', '')}`
    } as TrackingEventInfoModel;

    const model = { products: [{ name: ProductNames.thuisVeiligOnline }] };
    if (productName !== ProductNames.thuisVeiligOnline) {
      model.products.push({ name: productName });
    }
    return this.productService.trackedOrder(Endpoints.productsAdd, model, trackingInfo);
  }

  changeVeiligOnlineNoOfDevices(newProductName: string, previousTechnicalId: string, changeType: OveralVeiligOnlineChangeTypeEnum): Observable<boolean> {
    const model = { products: [{ name: newProductName, previousId: previousTechnicalId }] };
    const trackingInfo = {
      trackingCategory: 'SS - Veilig Online wijzigen',
      trackingLabel: changeType === OveralVeiligOnlineChangeTypeEnum.Upgrade ? 'Meer licenties' : 'Minder licenties'
    } as TrackingEventInfoModel;
    return this.productService.trackedOrder(Endpoints.productsModify, model, trackingInfo);
  }

  downgradeToVeiligOnline(ovoProduct?: SubscriptionOwnedProductModel): Observable<boolean> {
    if (!ovoProduct) {
      return of(false);
    }
    const model = { products: [{ name: ovoProduct.name, technicalId: ovoProduct.technicalId }] };

    const trackingInfo = {
      trackingCategory: 'SS - Veilig Online wijzigen',
      trackingLabel: 'Thuis'
    } as TrackingEventInfoModel;

    return this.productService.trackedOrder(Endpoints.productsCease, model, trackingInfo);
  }

  removeVeiligOnline(products: SubscriptionOwnedProductModel[]): Observable<boolean> {
    const model = {
      products: products.map(product => ({
        name: product.name,
        technicalId: product.technicalId
      }))
    };

    const gaEventTrackingLabel =
      model.products.length === 2 ?
        `Thuis + ${model.products[1].name}`.replace('Veilig Online voor ', '') :
        'Thuis';

    const trackingInfo = {
      trackingCategory: 'SS - Veilig Online uit',
      trackingLabel: gaEventTrackingLabel
    } as TrackingEventInfoModel;

    return this.productService.trackedOrder(Endpoints.productsCease, model, trackingInfo);
  }

  updateToThuisOveral(productName: string): Observable<boolean> {
    const trackingInfo = {
      trackingCategory: 'SS - Veilig Online wijzigen',
      trackingLabel: 'Thuis + Overal'
    } as TrackingEventInfoModel;

    const model = { products: [{ name: productName }] };
    return this.productService.trackedOrder(Endpoints.productsAdd, model, trackingInfo);
  }

  veiligOnlineCustomer(): Observable<FSecureCustomerModel> {
    return this.httpClient.get<FSecureCustomerModel>(Endpoints.fSecureCustomer);
  }
}
