import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BasicCustomerInformationViewModel,
  ContractInfoModel,
  CustomerInformationViewModel,
  PasswordChangeModel,
  PaymentInformationModel,
  PrivacySettingsModel,
  ResultViewModel,
  SetPhoneNumbersCommand
} from 'app/shared/open-api/data-contracts';
import { TrackingEventInfoModel } from 'app/shared/tracking.models';
import { TrackingService } from 'app/shared/tracking.service';
import { FileSaverService } from 'ngx-filesaver';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';
import { Endpoints } from '../Endpoints';

@Injectable()
export class AccountService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly fileSaverService: FileSaverService,
    private readonly trackingService: TrackingService
  ) {}

  changePassword(model: PasswordChangeModel): Observable<ResultViewModel> {
    const trackingInfo = {
      trackingCategory: 'SS - Wachtwoord',
      trackingLabel: 'Wachtwoord wijzigen'
    } as TrackingEventInfoModel;

    return this.trackingService.trackedPost<ResultViewModel>(Endpoints.accountChangePassword, model, trackingInfo);
  }

  contractInfoObservable(): Observable<ContractInfoModel | null> {
    return this.httpClient.get<ContractInfoModel>(Endpoints.accountContractInfo);
  }

  getAccountDetails(): Observable<CustomerInformationViewModel> {
    return this.httpClient.get<CustomerInformationViewModel>(Endpoints.account).pipe(first());
  }

  getBasicCustomerData(): Observable<BasicCustomerInformationViewModel | null> {
    return this.httpClient.get<BasicCustomerInformationViewModel>(Endpoints.accountBasic);
  }

  getContractPdf(): Promise<any> {
    const trackingInfo = {
      trackingCategory: 'SS - Download',
      trackingLabel: 'Contract'
    } as TrackingEventInfoModel;

    return this.trackingService
      .trackedGetAsBlob(Endpoints.accountContractPdf, trackingInfo)
      .pipe(map((res) => this.fileSaverService.save(res, 'contract.pdf')))
      .toPromise();
  }

  getPaymentInformation(): Observable<PaymentInformationModel | null> {
    return this.httpClient.get<PaymentInformationModel>(Endpoints.accountPaymentInformation).pipe(first());
  }

  getPrivacySettings(): Observable<PrivacySettingsModel | null> {
    return this.httpClient.get<PrivacySettingsModel>(Endpoints.accountPrivacy).pipe(first());
  }

  setPaymentInformation(
    model: PaymentInformationModel,
    changedName: boolean,
    changedIban: boolean,
    changedBillCycle: boolean
  ): Observable<any> {
    const events: TrackingEventInfoModel[] = [];

    if (changedName || changedIban) {
      const changes: string[] = [];
      if (changedName) {
        changes.push('Naam rekeninghouder');
      }
      if (changedIban) {
        changes.push('IBAN');
      }
      events.push(new TrackingEventInfoModel('SS - Betaalgegevens', this.trackingService.joinTrackingLabels(changes)));
    }
    if (changedBillCycle) {
      events.push(new TrackingEventInfoModel('SS - incassodatum wijzigen', 'incassodatum wijzigen'));
    }

    // A special version, it tracks both as success or failure depending on how it went.
    return this.httpClient.put(Endpoints.accountPaymentInformation, model).pipe(
      tap(() => {
        events.forEach((e) => {
          this.trackingService.trackChangeSuccess(e);
        });
      }),
      catchError((ex) => {
        events.forEach((e) => {
          this.trackingService.trackChangeError(e);
        });

        return throwError(ex);
      })
    );
  }

  setPhoneNumbers(model: SetPhoneNumbersCommand): Observable<string> {
    const trackingInfo = {
      trackingCategory: 'SS - Contactgegevens',
      trackingLabel: 'Telefoonnummer'
    } as TrackingEventInfoModel;

    return this.trackingService.trackedPostAsText(Endpoints.accountSetPhoneNumbers, model, trackingInfo);
  }

  setPrimaryEmail(primaryEmailAddress: string): Observable<any> {
    const model = { primaryEmailAddress };

    const trackingInfo = {
      trackingCategory: 'SS - Contactgegevens',
      trackingLabel: 'E-mailadres'
    } as TrackingEventInfoModel;

    return this.trackingService.trackedPost(Endpoints.accountPrimaryEmail, model, trackingInfo);
  }

  setPrivacySettings(model: PrivacySettingsModel): Observable<any> {
    const trackingInfo = {
      trackingCategory: 'SS - Privacy instellingen',
      trackingLabel: 'Privacy instellingen wijzigen'
    } as TrackingEventInfoModel;

    return this.trackingService.trackedPost(Endpoints.accountPrivacy, model, trackingInfo);
  }
}
