import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { ConvergedStatusViewModel } from 'app/shared/open-api/data-contracts';
import { Observable, of } from 'rxjs';
import { ConvergedService } from '../services/converged.service';

/** A safe version of the converged status resolver, capable of taking into account that the customer is not yet known to the ConvergedService */
@Injectable()
export class SafeConvergedStatusResolver implements Resolve<ConvergedStatusViewModel | null> {
  constructor(private readonly convergedService: ConvergedService, private readonly authService: AuthService) {}
  resolve(): Observable<ConvergedStatusViewModel | null> {
    return this.authService.customerInformation?.noConvergedInfoYet === false
      ? this.convergedService.customerIsConvergedV2()
      : of(null);
  }
}
