import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoadingIndicatorService } from '../loading-indicator.service';
import { NavigationService } from '../navigation.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent {
  pageTitle$: Observable<string | null>;
  routerSubscription: Subscription;

  constructor(
    private readonly loadingIndicatorService: LoadingIndicatorService,
    private readonly navigationService: NavigationService,
    private readonly router: Router
  ) {
    this.pageTitle$ = this.navigationService.navigationModel.pipe(map((x) => (x?.hideTitle === false ? x.title : null)));
  }

  get loadFailed(): boolean {
    return this.loadingIndicatorService.pageFailedLoading;
  }

  @HostListener('document:click', ['$event'])
  handleClick(event: Event): void {
    if (event.target instanceof HTMLAnchorElement) {
      const element = event.target as HTMLAnchorElement;
      if (element.className === 'routerlink') {
        event.preventDefault();
        const route = element?.getAttribute('href');

        if (route) {
          const params = route.split('?')[1]?.split('&') || [];

          if (params?.length) {
            const extras: { [k: string]: any } = {};
            params.forEach((e) => {
              const split = e.split('=');
              extras[split[0]] = split[1] || '';
            });

            this.router.navigate([route.split('?')[0]], { state: extras });
          } else {
            this.router.navigate([`/${route}`]);
          }
        }
      }
    }
  }
}
