import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MonthlyVoDUsageModel, MonthlyVoipUsageModel } from 'app/shared/open-api/data-contracts';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Endpoints } from '../Endpoints';

@Injectable()
export class UsageService {
  constructor(private readonly httpClient: HttpClient) {}

  getVoDUsage(): Observable<MonthlyVoDUsageModel[] | null> {
    return this.httpClient.get<MonthlyVoDUsageModel[]>(Endpoints.usageVod).pipe(first());
  }

  getVoipUsage(): Observable<MonthlyVoipUsageModel[] | null> {
    return this.httpClient.get<MonthlyVoipUsageModel[]>(Endpoints.usageVoip).pipe(first());
  }

}
