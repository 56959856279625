import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HasGdprDataModel } from 'app/shared/open-api/data-contracts';
import { FileSaverService } from 'ngx-filesaver';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Endpoints } from '../Endpoints';

@Injectable()
export class GdprService {
  constructor(private http: HttpClient, private fileSaverService: FileSaverService) {}

  getGdprData(): Promise<any> {
    return this.http
      .get(Endpoints.gdprData, { responseType: 'blob' })
      .pipe(map((res) => this.fileSaverService.save(res, 'gdprdata.txt')))
      .toPromise();
  }

  hasGdprData(): Observable<HasGdprDataModel> {
    return this.http.get<HasGdprDataModel>(Endpoints.gdprAvailable);
  }
}
