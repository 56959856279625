import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Event, NavigationEnd, Router } from '@angular/router';
import { Breadcrumb } from 'app/core/breadcrumb/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private breadcrumbs = new Array<Breadcrumb>();
  breadcrumbChanged = new EventEmitter<Breadcrumb[]>(false);

  constructor(private router: Router) {
    this.router.events.subscribe((routeEvent) => {
      this.onRouteEvent(routeEvent);
    });
  }

  getCrumbs(): Breadcrumb[] {
    let route = this.router.routerState.root.snapshot;
    let url = '';

    let breadCrumbIndex = 0;
    const newCrumbs: Breadcrumb[] = [];

    while (route.firstChild != null) {
      route = route.firstChild;

      if (route.routeConfig === null) {
        continue;
      }
      if (!route.routeConfig.path) {
        continue;
      }

      url += `/${route.url.map((s) => s.toString()).join('/')}`;

      if (!route.data['title']) {
        continue;
      }

      const newCrumb = this.createBreadcrumb(route, url);

      if (breadCrumbIndex < this.breadcrumbs.length) {
        const existing = this.breadcrumbs[breadCrumbIndex++];

        if (existing && existing.route === route.routeConfig) {
          newCrumb.displayName = existing.displayName;
        }
      }

      newCrumbs.push(newCrumb);

      if (route.data['ignoreBreadcrumbParent'] === true) {
        newCrumbs.splice(newCrumbs.length - 2, 1);
      }

      if (route.data['breadCrumbTitle']) {
        newCrumb.displayName = route.data['breadCrumbTitle'];
      }
    }

    return newCrumbs;
  }

  /**
   *
   * @param urls starts from parent URL
   */
  updateBreadcrumbUrls(urls: string[]): void {
    if (this.breadcrumbs.length > 1 && urls.length > 0) {
      for (let index = 0; index < urls.length; index++) {
        this.breadcrumbs[this.breadcrumbs.length - 2 - index].url = urls[index];
      }
    }
    this.breadcrumbChanged.emit(this.breadcrumbs);
  }

  updateLastBreadcrumb(title: string): void {
    if (this.breadcrumbs.length) {
      this.breadcrumbs[this.breadcrumbs.length - 1].displayName = title;
    }
    this.breadcrumbChanged.emit(this.breadcrumbs);
  }

  updateParentBreadcrumbUrl(url: string): void {
    if (this.breadcrumbs.length > 1 && url) {
      this.breadcrumbs[this.breadcrumbs.length - 2].url = url;
    }
    this.breadcrumbChanged.emit(this.breadcrumbs);
  }

  private createBreadcrumb(route: ActivatedRouteSnapshot, url: string): Breadcrumb {
    let targetRoute = url;

    if (route.data['breadcrumbTarget']) {
      targetRoute = route.data['breadcrumbTarget'];
    }

    return {
      displayName: route.data['title'],
      url: targetRoute,
      route: route.routeConfig
    };
  }

  private onRouteEvent(routeEvent: Event) {
    if (!(routeEvent instanceof NavigationEnd)) {
      return;
    }
    const newCrumbs = this.getCrumbs();

    const breadCrumbsEqual =
      this.breadcrumbs.length === newCrumbs.length &&
      this.breadcrumbs.every((bc) =>
        newCrumbs.some((obc) => obc.displayName === bc.displayName && obc.route?.path === bc.route?.path)
      );

    if (breadCrumbsEqual === false) {
      this.breadcrumbs = newCrumbs;
      this.breadcrumbChanged.emit(this.breadcrumbs);
    }
  }
}
