<app-navigation></app-navigation>

<section class="section section-square-pattern">
  <div class="container px-tablet-0">
    <div class="row no-gutters">
      <div class="column-12 column-desktop-large-8 mx-desktop-auto">
        <app-page-header *ngIf="pageTitle$ | async as title" [pageTitle]="title"></app-page-header>
        <router-outlet #outlet="outlet"></router-outlet>
        <div *ngIf="loadFailed"><h2>Oeps! Er is iets fout gegaan.</h2></div>
      </div>
    </div>
  </div>
</section>
