import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { AccountService } from 'app/core/services/account.service';
import { BasicCustomerInformationViewModel } from 'app/shared/open-api/data-contracts';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

declare let window: any;

@Component({
  selector: 'app-nav-item-account',
  templateUrl: './navigation-item-account.component.html',
  styleUrls: ['./navigation-item-account.component.scss']
})
export class NavigationItemAccountComponent implements OnDestroy, OnInit {
  private subscription: Subscription;

  customerInitials: string;
  magentaAccountUrl: string;

  constructor(private readonly authService: AuthService, private readonly accountService: AccountService) {}

  logout(): void {
    this.authService.startLogout();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnInit() {
    this.subscription = this.accountService
      .getBasicCustomerData()
      .pipe(first())
      .subscribe((data: BasicCustomerInformationViewModel | null) => {
        this.customerInitials = data?.customerInitials ?? '';
      });

    this.magentaAccountUrl = (window as any).magentaAccountUrl;
  }
}
