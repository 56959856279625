<!-- Main Menu -->
<div class="iconnav">
  <div class="container px-0">
    <div class="row no-gutters">
      <div class="column-12 mx-auto column-desktop-8">
        <div class="row no-gutters">
          <ng-container *ngFor="let link of links">
            <div *ngIf="link" class="column">
              <a *ngIf="!link.url" class="iconnav-link" role="button" data-modal-toggle="#modal-menu-1">
                <!-- open sub menu -->
                <span class="iconnav-icon {{ link.icon }}"></span>
                <span class="iconnav-label">{{ link.text }}</span>
              </a>
              <a *ngIf="link.url && link.url[0] === '/'" class="iconnav-link" [routerLink]="link.url"
                routerLinkActive="is-active" [appTrackingNav]="link.trackingLabel">
                <!-- navigate to route -->
                <span class="iconnav-icon {{ link.icon }}"></span>
                <span class="iconnav-label">{{ link.text }}</span>
              </a>
              <a *ngIf="link.url && link.url[0] !== '/'" class="iconnav-link" [href]="link.url"
                [appTrackingNav]="link.trackingLabel">
                <!-- navigate to url -->
                <span class="iconnav-icon {{ link.icon }}"></span>
                <span class="iconnav-label">{{ link.text }}</span>
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
