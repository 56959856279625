<div class="localnav has-background" [class.is-transparent]="isSubMenu">
  <div class="container-desktop container-fluid">
    <div class="row">

      <div class="column-6 column-tablet-6 column-desktop-3 align-self-center text-left py-4 py-desktop-0 order-1 order-desktop-1 flex align-items-center" *ngIf="backLink">
        <a [routerLink]="backLink.url" class="button-inverted" *ngIf="!isMagentaUrl(backLink.url); else externalUrl">
          <span class="icon-arrow-left"></span>
          <span *ngIf="backLink.text" class="ml-2">{{ backLink.text }}</span>
        </a>
        <ng-template #externalUrl>
          <a [href]="backLink.url" class="link-back">{{ backLink.text }}</a>
        </ng-template>
      </div>

      <div class="column-12 column-tablet-12 column-desktop-6 align-self-center text-center py-4 py-desktop-6 order-3 order-desktop-2">
        <h1 class="mb-0 localnav-title">{{ title }}</h1>
      </div>

      <div class="column-6 column-tablet-6 column-desktop-3 align-self-center text-right py-4 py-desktop-6 order-2 order-desktop-3">
        <a *ngIf="quickLink" [routerLink]="quickLink.url" class="text-link-black">{{ quickLink.text }}</a>
      </div>

    </div>
  </div>
</div>
