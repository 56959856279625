import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceIncidentsViewModel } from 'app/shared/open-api/data-contracts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Endpoints } from '../Endpoints';

@Injectable()
export class OutageService {
  constructor(private readonly httpClient: HttpClient) {}

  getIncidents(): Observable<ServiceIncidentsViewModel> {
    return this.httpClient
      .get<ServiceIncidentsViewModel>(Endpoints.outageIncidents)
      .pipe(map((i) => (i ? i : ({} as ServiceIncidentsViewModel))));
  }
}
