import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  TransferStatusModel,
  UpdateDossierByReferenceViewModel,
  UpdateDossierViewModel
} from 'app/shared/open-api/data-contracts';
import { TrackingEventInfoModel } from 'app/shared/tracking.models';
import { TrackingService } from 'app/shared/tracking.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Endpoints } from '../Endpoints';

@Injectable()
export class TransferService {
  constructor(private readonly httpClient: HttpClient, private readonly trackingService: TrackingService) {}

  getActiveDossierStatus(userReference: string | null = null): Observable<TransferStatusModel | null> {
    const route = !!userReference ? Endpoints.transferByReference.format(userReference) : Endpoints.transfer;
    return this.httpClient.get<TransferStatusModel>(route).pipe(first());
  }

  updateDossier(model: UpdateDossierViewModel): Observable<any> {
    return this.trackingService.trackedPut(Endpoints.transferUpdate, model, this.trackingInfo('Overstappen pagina'));
  }

  updateDossierByReference(model: UpdateDossierByReferenceViewModel): Observable<boolean> {
    return this.trackingService.trackedPut<boolean>(
      Endpoints.transferUpdateByReference,
      model,
      this.trackingInfo('Persoonlijke url')
    );
  }

  private trackingInfo(label: string): TrackingEventInfoModel {
    return {
      trackingCategory: 'SS - Overstappen gegevens',
      trackingLabel: label
    } as TrackingEventInfoModel;
  }
}
