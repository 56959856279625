import { Pipe, PipeTransform } from '@angular/core';
import { ContentService } from 'app/shared/content.service';

@Pipe({
  name: 'content'
})
/** A pipe that can load content by key. */
export class ContentPipe implements PipeTransform {
  constructor(private readonly contentService: ContentService) { }

  transform(key: string): string {
    const brandSpanTag = 'Odido';
    const myBrandSpanTag = '<span class=\"text-nowrap\">My Odido</span>';
    const searchString = 'T-Mobile';

    if (!key) {
      return '';
    }

    const keys = key?.split(' ') ?? [];
    let value = keys.map((k) => this.contentService.content[k]).join(' ');

    if (!!value && value.indexOf(searchString) !== -1) {
      if (value.indexOf('<a') !== -1 && value.indexOf('My T-Mobile') !== -1) {
        value = value.replace(/My T-Mobile/g, myBrandSpanTag);
      } else {
        value = value.replace(/T-Mobile/g, brandSpanTag);
      }
    }
    if (localStorage.getItem('editMode') === '1') {
      return key;
    }
    return value;
  }
}
