<section class="section pt-desktop-7 pb-desktop-9">
  <div class="container">
    <div class="row text-center">
      <div class="column-tablet-12">
        <div class="cell">
          <div class="row justify-content-center mb-5 mb-desktop-7">
            <div class="column-5 column-desktop-7">
              <img
                src="/assets/images/errors/tmo_error_404.png"
                alt="404 image"
                style="max-height: 200px; max-width: 200px; width: 100%"
              />
            </div>
          </div>
          <div class="row justify-content-center mb-desktop-5">
            <div class="column-10 column-desktop-7">
              <h1 class="display-small mb-0">{{ header }}</h1>
            </div>
          </div>
          <div class="row justify-content-center mb-7">
            <div class="column-12 column-desktop-7">
              <p></p>
              <p>{{ description }}</p>
              <p></p>
            </div>
          </div>
          <div class="row justify-content-center text-center">
            <div class="column-12 column-desktop-7 flex-column flex-desktop-row">
              <a href="/" class="button-outline-base mr-desktop-5">Ga naar de homepagina</a>
              <a href="https://odido.nl/service" class="button-outline-base mt-5 mt-desktop-0">Service</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
