import shared from 'content/locale/nl-NL/shared.json';
import { ContentModel } from './models/content/content.model';

/** A service that can create dictionaries from content files. */
export class ContentService {
  content: { [key: string]: string };

  constructor(...data: ContentModel[][]) {
    const merged = data.reduce(
      (flat, toFlatten) => flat.concat(Array.isArray(toFlatten) ? ([] as ContentModel[]).concat.apply([], toFlatten) : toFlatten),
      []
    );
    this.content = this.load(merged);
  }

  private load(localization: ContentModel[]): { [key: string]: string } {
    const mapped: { [key: string]: string } = {};

    const append = (data: ContentModel[]) => {
      data.forEach((c) => {
        mapped[c.key] = c.value;
      });
    };

    // The shared file contains resources all sections of the application can use.
    append(shared);
    append(localization);

    const newLocales = localStorage.getItem('newLocales');
    if (newLocales) {
      const locales = new Set(JSON.parse(newLocales));
      locales.forEach((a: any) => {
        mapped[a.key] = a.value;
      })
    }
    return mapped;
  }
}
