import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingIndicatorService {
  private _isInitiallyLoading = true;

  private _pageBusy = false;
  private _pageFailedLoading: boolean;
  private _pageLoading: boolean;

  get pageBusy(): boolean {
    return this._pageBusy;
  }

  set pageBusy(value: boolean) {
    this._pageBusy = value;
  }

  get pageFailedLoading(): boolean {
    return this._pageFailedLoading;
  }

  get pageLoading(): boolean {
    return this._pageLoading;
  }

  get isInitiallyLoading(): boolean {
    return this._isInitiallyLoading;
  }

  setPageInitialized(): void {
    this._isInitiallyLoading = false;
  }

  setPageLoadComplete(): void {
    this._pageLoading = false;
    this._pageFailedLoading = false;
  }

  setPageLoadFailed(): void {
    this._pageLoading = false;
    this._pageFailedLoading = true;
    this._isInitiallyLoading = false;
  }

  setPageLoading(): void {
    this._pageLoading = true;
    this._pageFailedLoading = false;
  }
}
