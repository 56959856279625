import { Observable } from 'rxjs';

export class NavigationCategory {
  links: NavigationLink[];
  menuLevel: number;
  name: string;
}

export class NavigationLink {
  public visible = true;

  constructor(
    public text: string,
    public url: string,
    public icon?: string | undefined,
    public trackingLabel?: string | undefined,
    public visibleObs?: Observable<boolean> | undefined
  ) {
    if (!this.trackingLabel) {
      this.trackingLabel = this.text;
    }

    this.visible = !visibleObs;
    visibleObs?.subscribe({ next: (v) => (this.visible = v) });
  }
}
