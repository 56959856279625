import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { OpenSupportRequestModel } from 'app/shared/open-api/data-contracts';
import { Observable } from 'rxjs';
import { RemoteMechanicService } from '../services/remote-mechanic.service';

@Injectable()
export class OpenSupportRequestsResolver implements Resolve<Observable<OpenSupportRequestModel | null>> {
  constructor(private readonly remoteMechanicService: RemoteMechanicService) {}
  resolve(): Observable<OpenSupportRequestModel | null> {
    return this.remoteMechanicService.openSupportRequests();
  }
}
