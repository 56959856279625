import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ServiceIncidentsViewModel } from 'app/shared/open-api/data-contracts';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { OutageService } from '../services/outage.service';

@Injectable()
export class OutageResolver implements Resolve<any> {
  constructor(private outageService: OutageService) {}

  resolve(): Observable<ServiceIncidentsViewModel> {
    return this.outageService.getIncidents().pipe(first());
  }
}
