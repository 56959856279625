import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { UrlService } from 'app/core/services/url.service';
import { Feature } from 'app/shared/models/features/feature';

@Injectable({
  providedIn: 'root'
})
export class MigrationGuard implements CanActivate {

  constructor(
    private readonly authService: AuthService,
    private readonly feature: Feature,
    private readonly urlService: UrlService
  ) {}


  canActivate(route: ActivatedRouteSnapshot): boolean {

    if (!this.authService.customerInformation?.magentaVkfe) {
      return true;
    }

    if (!!this.feature.isEnabled && route.data?.migratedUrl) {
      this.urlService.setLocationHref(`${(window as any).magentaBaseUrl}/my/${route.data.migratedUrl}`);
      return false;
    }

    return true;
  }
}
