<div
  class="modal fade"
  [id]="modalMenuId"
  tabindex="-1"
  role="dialog"
  [attr.aria-labelledby]="modalMenuId"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-drawer modal-dialog-drawer-right">
    <div class="modal-content">
      <div class="modal-header">
        <h3 *ngIf="title" class="modal-title">{{ title }}</h3>
        <button type="button" class="modal-close-button" data-modal-dismiss="" aria-label="Close"></button>
      </div>

      <div class="modal-body">
        <ng-content select="[custom-links-top]"></ng-content>

        <ng-container *ngFor="let linkGroup of linkGroups">
          <div *ngIf="linkGroup.links?.length > 0" class="row mb-5">
            <div class="column-12">
              <h3 *ngIf="linkGroup.name">{{ linkGroup.name }}</h3>
              <ul class="list-group-inset">
                <ng-container *ngFor="let link of linkGroup.links">
                  <li *ngIf="link?.visible" class="is-clickable">
                    <!-- open sub menu -->
                    <div *ngIf="!link.url" 
                      class="flex align-items-center justify-content-between" 
                      [attr.data-modal-toggle]="modalSubMenuRef">

                      <div>{{ link.text }}</div>
                      <a class="stretched-link">
                        <span class="icon-arrow-right"></span>
                      </a>
                    </div>

                    <!-- navigate to route -->
                    <div
                      *ngIf="link.url && link.url[0] === '/'"
                      class="flex align-items-center justify-content-between"
                      data-modal-dismiss=""
                      (click)="closeParentModal()"
                    >
                      <div>{{ link.text }}</div>
                      <a class="stretched-link" [routerLink]="link.url" [appTrackingNav]="link.trackingLabel">
                        <span class="icon-arrow-right"></span>
                      </a>
                    </div>

                    <div *ngIf="link.url && link.url[0] !== '/'" class="flex align-items-center justify-content-between">
                      <!-- navigate to url -->
                      <div>{{ link.text }}</div>
                      <a class="stretched-link" [appTrackingNav]="link.trackingLabel" [href]="link.url">
                        <span class="icon-arrow-right"></span>
                      </a>
                    </div>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </ng-container>

        <ng-content select="[custom-links-bottom]"></ng-content>
      </div>
    </div>
  </div>
</div>
