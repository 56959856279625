import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';

@Injectable()
export class DemoGuard implements CanActivate {
  constructor(private readonly router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return of(environment.production ? this.router.createUrlTree(['/dashboard']) : true);
  }
}
