import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  VoipNumberBlockingModel,
  VoipNumberPortingViewModel,
  VoipSettingViewModel,
  VoipSipPasswordModel,
  VoipVoicemailAndForwardingModel
} from 'app/shared/open-api/data-contracts';
import { TrackingEventInfoModel } from 'app/shared/tracking.models';
import { TrackingService } from 'app/shared/tracking.service';
import { Observable, of } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';
import { Endpoints } from '../Endpoints';

@Injectable()
export class PhonenumbersService {
  constructor(private readonly httpClient: HttpClient, private readonly trackingService: TrackingService) {}

  checkVoipNetCode(phonenumber: string, postalCode: string = '') {
    return this.httpClient.get<boolean>(Endpoints.voipCheckNetCode.format(phonenumber, postalCode)).pipe(first());
  }

  getPhonenumberDetails(id: string): Observable<VoipSettingViewModel | null> {
    return this.getPhonenumbers().pipe(
      concatMap((phonenumbers: VoipSettingViewModel[]) => {
        const phonenumber = phonenumbers.find((p) => p.productCode === id);
        return of(phonenumber || null);
      }),
      first()
    );
  }

  getPhonenumbers(): Observable<VoipSettingViewModel[]> {
    return this.httpClient.get<VoipSettingViewModel[]>(Endpoints.voipSettings);
  }

  updateNumberBlocking(numberblockingModel: VoipNumberBlockingModel): Observable<VoipSettingViewModel[]> {
    return this.updateSettings(Endpoints.voipSettingsNumberBlocking, numberblockingModel, 'Blokkeringen');
  }

  updateNumberPorting(numberportingModel: VoipNumberPortingViewModel): Observable<VoipSettingViewModel[]> {
    return this.updateSettings(Endpoints.voipSettingsNumberPorting, numberportingModel, 'Nummerbehoud aanvragen');
  }

  updateSipPassword(sipPasswordModel: VoipSipPasswordModel): Observable<VoipSettingViewModel[]> {
    return this.updateSettings(Endpoints.voipSettingsSipPassword, sipPasswordModel, 'SIP wachtwoord');
  }

  updateVoicemailAndForwardingSettings(
    voicemailAndForwardingModel: VoipVoicemailAndForwardingModel
  ): Observable<VoipSettingViewModel[]> {
    return this.updateSettings(Endpoints.voipSettingsVoicemail, voicemailAndForwardingModel, 'Voicemail & doorschakelen');
  }

  private updateSettings(route: string, model: any, trackingLabel: string): Observable<VoipSettingViewModel[]> {
    const trackingInfo = {
      trackingCategory: 'SS - Bellen instellingen',
      trackingLabel
    } as TrackingEventInfoModel;

    return this.trackingService.trackedPostAsText(route, model, trackingInfo).pipe(concatMap(() => this.getPhonenumbers()));
  }
}
