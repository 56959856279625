import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProductNames } from 'app/shared/models/productnames.model';
import { ShopOrderSetTopBoxModel } from 'app/shared/models/products/shop-order-set-top-box.model';
import {
  Address,
  AvailableProductsViewModel,
  CeaseProductCommand,
  InternetChangeModel,
  InternetChangeProductModel,
  MoveAddressResultModel,
  NetworkMigrationCommand,
  NetworkMigrationPreOrderCommand,
  OrderResultModel,
  ShopVoipProductModel,
  ShopWifiExtenderProductModel,
  ShopWifiExtenderProductsModel,
  SubscriptionAvailableProductModel,
  SubscriptionModel,
  SubscriptionOwnedProductModel,
  VoipOrderCommand,
  VoipProductsModel
} from 'app/shared/open-api/data-contracts';
import { TrackingEventInfoModel } from 'app/shared/tracking.models';
import { TrackingService } from 'app/shared/tracking.service';
import { Observable, of } from 'rxjs';
import { concatMap, first, map, tap } from 'rxjs/operators';
import { Endpoints } from '../Endpoints';
import { RequestCache } from '../request-cache';

@Injectable()
export class ProductsService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly trackingService: TrackingService,
    private readonly requestCache: RequestCache
  ) { }

  addMvpl(product: SubscriptionAvailableProductModel, voucherName: string, trackingInfo?: TrackingEventInfoModel): Observable<boolean> {
    const model = { products: [{ name: product.name, voucherName }] };
    if (!trackingInfo) {
      trackingInfo = {
        trackingCategory: 'SS - TV pakket aan',
        trackingLabel: product.displayName
      } as TrackingEventInfoModel
    }
    return this.trackedOrder(Endpoints.productsAdd, model, trackingInfo);
  }

  addSetTopBoxes(orderModel: ShopOrderSetTopBoxModel, shippingAddress: Address | null): Observable<any> {
    const model = {
      products: Array(orderModel.stbsCount).fill({ name: orderModel.productName }),
      shippingAddress
    };

    const trackingInfo = {
      trackingCategory: shippingAddress ? 'SS - TV Box toevoegen - Afwijkend Bezorgadres' : 'SS - TV Box toevoegen',
      trackingLabel: orderModel.stbsCount.toString()
    } as TrackingEventInfoModel;

    return this.trackingService.trackedPost(Endpoints.productsAdd, model, trackingInfo).pipe(
      tap(() => {
        this.requestCache.clearFor(Endpoints.setTopBoxesInfo);
      })
    );
  }

  addSportsbundle(voucherName: string, trackingInfo?: TrackingEventInfoModel): Observable<boolean> {
    const model = { products: [
      { name: ProductNames.viaplay },
      { name: ProductNames.espnCompleet, voucherName }
    ] };

    if (!trackingInfo) {
      trackingInfo = {
        trackingCategory: 'SS - Sportbundel aan',
        trackingLabel: 'Sportbundel aan'
      };
    }

    return this.trackedOrder(Endpoints.productsAdd, model, trackingInfo).pipe(
      tap(() => {
        this.requestCache.clearFor(Endpoints.productsAdd);
      })
    );
  }

  addViaplay(voucherName: string, trackingInfo?: TrackingEventInfoModel): Observable<boolean> {
    const model = { products: [{ name: ProductNames.viaplay, voucherName }] };
    if (!trackingInfo) {
      trackingInfo = {
        trackingCategory: `SS - ${ProductNames.viaplay} aan`,
        trackingLabel: `${ProductNames.viaplay} aan`
      }
    }

    return this.trackedOrder(Endpoints.productsAdd, model, trackingInfo).pipe(
      tap(() => {
        this.requestCache.clearFor(Endpoints.entertainmentViaplay);
      })
    );
  }

  addVoip(model: VoipOrderCommand, product: ShopVoipProductModel): Observable<boolean> {
    const trackingInfo = {
      trackingCategory: 'SS - Vast Bellen aan',
      trackingLabel: product.displayName
    } as TrackingEventInfoModel;

    return this.trackedOrder(Endpoints.productsVoip, model, trackingInfo);
  }

  ceaseVoip(products: SubscriptionOwnedProductModel[]): Observable<boolean> {
    const trackingInfo = {
      trackingCategory: 'SS - Vast Bellen uit',
      trackingLabel: products[0].displayName
    } as TrackingEventInfoModel;

    return this.trackedOrder(
      Endpoints.productsCease,
      {
        products: products.map((p) => ({ name: p.name, technicalId: p.technicalId } as CeaseProductCommand))
      },
      trackingInfo
    ).pipe(
      tap(() => {
        this.requestCache.clearFor(Endpoints.productsVoip);
      })
    );
  }

  deleteSetTopBoxes(setTopBoxesToDelete: SubscriptionOwnedProductModel[], anyStbLeft: boolean): Observable<boolean> {
    const trackingLabel = !anyStbLeft ? `${setTopBoxesToDelete.length} - All` : null;

    return this.trackedCease(setTopBoxesToDelete, 'SS - TV Box verwijderen', trackingLabel).pipe(
      tap(() => {
        this.requestCache.clearFor(Endpoints.setTopBoxesInfo);
      })
    );
  }

  deleteWifiExtenders(products: SubscriptionOwnedProductModel[]): Observable<boolean> {
    return this.trackedCease(products, 'SS - Wifi-punt opzeggen');
  }

  getAvailableInternetProducts(): Observable<InternetChangeModel | null> {
    return this.httpClient.get<InternetChangeModel>(Endpoints.productInternet).pipe(first());
  }

  getAvailableProductsForCustomer(address?: MoveAddressResultModel | null): Observable<AvailableProductsViewModel> {
    return this.httpClient.get<AvailableProductsViewModel>(Endpoints.productsAvailable, { params: address as HttpParams }).pipe(first());
  }

  getAvailableVoipProducts(): Observable<VoipProductsModel> {
    return this.httpClient
      .get<VoipProductsModel>(Endpoints.productsVoip)
      .pipe(map((cvm) => (!cvm ? ({} as VoipProductsModel) : cvm)));
  }

  getAvailableWifiExtenderProducts(): Observable<ShopWifiExtenderProductsModel | null> {
    return this.httpClient.get<ShopWifiExtenderProductsModel>(Endpoints.productsWifiExtender).pipe(first());
  }

  getSubscriptionForCustomer(): Observable<SubscriptionModel> {
    return this.httpClient.get<SubscriptionModel>(Endpoints.subscription).pipe(
      first(),
      map((s) => (!s ? ({} as SubscriptionModel) : s))
    );
  }

  orderInternet(product: InternetChangeProductModel, previousTechnicalId: string): Observable<boolean> {
    const model = { products: [{ name: product.name, previousId: previousTechnicalId }] };
    const trackingInfo = {
      trackingCategory: 'SS - Internetsnelheid',
      trackingLabel: product.displayName
    } as TrackingEventInfoModel;

    return this.trackedOrder(Endpoints.productsModify, model, trackingInfo);
  }

  orderVoip(product: ShopVoipProductModel): Observable<boolean> {
    const model = { name: product.name } as VoipOrderCommand;
    const trackingInfo = {
      trackingCategory: 'SS - Vast Bellen wijzigen',
      trackingLabel: product.displayName
    } as TrackingEventInfoModel;

    return this.trackedOrder(Endpoints.productsVoip, model, trackingInfo);
  }

  orderWifiExtenders(products: ShopWifiExtenderProductModel[], shippingAddress: Address | null): Observable<boolean> {
    const model = {
      products: products.map((p) => ({ name: p.name })),
      shippingAddress
    };

    const trackingInfo = {
      trackingCategory: shippingAddress ? 'SS - Wifi-punt bestellen - Afwijkend Bezorgadres' : 'SS - Wifi-punt bestellen',
      trackingLabel: products.length.toString()
    } as TrackingEventInfoModel;

    return this.trackedOrder(Endpoints.productsAdd, model, trackingInfo);
  }

  placeNetworkMigrationOrder(model: NetworkMigrationCommand): Observable<boolean> {
    const trackingInfo = {
      trackingCategory: 'SS - Netwerk wijzigen',
      trackingLabel: 'Bevestigd'
    } as TrackingEventInfoModel;

    return this.trackedOrder(Endpoints.productNetworkMigration, model, trackingInfo);
  }

  placeNetworkMigrationPreOrder(model: NetworkMigrationPreOrderCommand): Observable<boolean> {
    const trackingInfo = {
      trackingCategory: 'SS - Netwerk wijzigen preorder',
      trackingLabel: 'Bevestigd'
    } as TrackingEventInfoModel;

    return this.trackedOrder(Endpoints.productNetworkMigrationPreOrder, model, trackingInfo);
  }

  removeMvpl(product: SubscriptionOwnedProductModel): Observable<boolean> {
    const model = {
      products: [
        {
          name: product.name,
          technicalId: product.technicalId
        }
      ]
    };

    const trackingInfo = {
      trackingCategory: 'SS - TV pakket uit',
      trackingLabel: product.displayName
    } as TrackingEventInfoModel;

    return this.trackedOrder(Endpoints.productsCease, model, trackingInfo);
  }

  removeSportsbundle(products: SubscriptionOwnedProductModel[]): Observable<boolean> {
    const model = {
      products: products.map(product => ({
        name: product.name,
        technicalId: product.technicalId
      }))
    };

    const trackingInfo = {
      trackingCategory: 'SS - Sportbundel uit',
      trackingLabel: 'Sportbundel uit'
    } as TrackingEventInfoModel;

    return this.trackedOrder(Endpoints.productsCease, model, trackingInfo);
  }

  removeViaplay(product: SubscriptionOwnedProductModel): Observable<boolean> {
    const model = {
      products: [
        {
          name: product.name,
          technicalId: product.technicalId
        }
      ]
    };

    const trackingInfo = {
      trackingCategory: `SS - ${ProductNames.viaplay} uit`,
      trackingLabel: `${ProductNames.viaplay} uit`
    } as TrackingEventInfoModel;

    return this.trackedOrder(Endpoints.productsCease, model, trackingInfo);
  }

  trackedCease(
    products: SubscriptionOwnedProductModel[],
    trackingCategory: string,
    trackingLabel: string | null = null
  ): Observable<boolean> {
    const model = {
      products: products.map((p) => ({
        name: p.name,
        technicalId: p.technicalId
      }))
    };

    const trackingInfo = {
      trackingCategory,
      trackingLabel: trackingLabel ?? products.length.toString()
    } as TrackingEventInfoModel;

    return this.trackingService
      .trackedPost<OrderResultModel>(Endpoints.productsCease, model, trackingInfo)
      .pipe(concatMap(() => of(true)));
  }

  trackedOrder(route: string, model: any, trackingInfo: TrackingEventInfoModel): Observable<boolean> {
    return this.trackingService.trackedPost<OrderResultModel>(route, model, trackingInfo).pipe(concatMap(() => of(true)));
  }
}
