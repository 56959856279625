import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ProductOrderHistoryModel } from 'app/shared/open-api/data-contracts';
import { Observable } from 'rxjs';
import { OrderService } from '../services/order.service';

@Injectable()
export class VeiligOnlineProductOrderHistoryResolver implements Resolve<any> {
  constructor(private orderService: OrderService) { }

  resolve(): Observable< ProductOrderHistoryModel[] | null> {
    return this.orderService.getProductOrderHistory({ key: 'PartnerName', value: 'F-Secure' });
  }
}
