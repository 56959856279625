import { Injectable } from '@angular/core';

/**
 * Set the BrowserStorage to use the same storage in AppModule.
 */
@Injectable({
  providedIn: 'root'
})

export abstract class BrowserStorage {
  private hasStorage: boolean;
  private storage: Storage;

  constructor() {
    this.hasStorage = typeof Storage !== 'undefined';
    this.storage = this.getStorage();
  }

  get(key: string): string | null {
    if (this.hasStorage) {
      return this.storage.getItem(this.prefix + key);
    }

    return null;
  }

  abstract getPrefix(): string;
  abstract getStorage(): Storage;

  get prefix(): string {
    return this.getPrefix();
  }

  remove(key: string): void {
    this.storage.removeItem(this.prefix + key);
  }

  set(key: string, value: string): void {
    if (this.hasStorage) {
      this.storage.setItem(this.prefix + key, value);
    }
  }
}
