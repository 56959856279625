import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  InstallmentPlanCommand,
  InstallmentPlanEligibilityModel,
  InstallmentPlanModel
} from 'app/shared/open-api/data-contracts';
import { TrackingEventInfoModel } from 'app/shared/tracking.models';
import { TrackingService } from 'app/shared/tracking.service';
import { Observable, of } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';
import { Endpoints } from '../Endpoints';

@Injectable()
export class InstallmentPlanService {
  constructor(private readonly httpClient: HttpClient, private trackingService: TrackingService) {}

  createInstallmentPlan(model: InstallmentPlanCommand): Observable<any> {
    const trackingInfo = {
      trackingCategory: 'SS - Betalingsregeling aanvragen',
      trackingLabel: 'Betalingsregeling aanvragen'
    } as TrackingEventInfoModel;

    return this.trackingService.trackedPost<any>(Endpoints.installmentplan, model, trackingInfo).pipe(concatMap(() => of(true)));
  }

  getInstallmentPlan(): Observable<InstallmentPlanModel | null> {
    return this.httpClient.get<InstallmentPlanModel>(Endpoints.installmentplan).pipe(first());
  }

  getInstallmentPlanEligibility(): Observable<InstallmentPlanEligibilityModel | null> {
    return this.httpClient.get<InstallmentPlanEligibilityModel>(Endpoints.installmentplanEligibility).pipe(first());
  }
}
