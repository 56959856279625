import { Directive, Input } from '@angular/core';
import { TrackingService } from '../tracking.service';
import { TrackingDirective } from './tracking.directive';

@Directive({
  selector: '[appTrackingNav]'
})
/** A directive to track Navigation clicks */
export class TrackingNavDirective extends TrackingDirective {

  @Input('appTrackingNav') trackingLabel: string;

  constructor(trackingService: TrackingService) {
    super(trackingService);
    this.trackingCategory = 'Thuismy - navigatie';
  }
}
