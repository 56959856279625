import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { UrlService } from 'app/core/services/url.service';

declare let window: any;

@Injectable()
export class MigratedUserGuard implements CanActivate {
  constructor(private readonly authService: AuthService, private urlService: UrlService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean  {

    if (!this.authService.customerInformation?.magentaVkfe) {
      return true;
    }
    
    if (route.data?.migratedUrl) {
      this.urlService.setLocationHref(`${(window as any).magentaBaseUrl}/my/${route.data.migratedUrl}`);
    }
    
    return false;
  }
}
