import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { VoipSettingViewModel } from 'app/shared/open-api/data-contracts';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { PhonenumbersService } from '../services/phonenumbers.service';

@Injectable()
export class PhoneNumberResolver implements Resolve<VoipSettingViewModel[]> {
  constructor(private phoneNumberService: PhonenumbersService) {}

  resolve(): Observable<VoipSettingViewModel[]> {
    return this.phoneNumberService.getPhonenumbers().pipe(first());
  }
}
