import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SubscriptionStatusEnum } from 'app/shared/models/subscription/subscription-status.enum';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Endpoints } from '../Endpoints';

@Injectable()
export class SubscriptionService {
  constructor(private readonly httpClient: HttpClient) {}

  /** Get the status of the customers' subscription. (Moving / renewal / initial / ceasing, etc.) */
  getSubscriptionStatus(): Observable<SubscriptionStatusEnum | null> {
    return this.httpClient.get<SubscriptionStatusEnum>(Endpoints.subscriptionStatus).pipe(first());
  }
}
