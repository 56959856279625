import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { NavigationCategory } from 'app/core/navigation/models/navigation-link';
import { NavigationService } from 'app/core/navigation.service';
import { ProductsService } from 'app/core/services/products.service';
import { TransferService } from 'app/core/services/transfer.service';
import { UserInformationModel } from 'app/shared/userinformation.models';
import { Subscription } from 'rxjs';
import { Breadcrumb } from '../breadcrumb/breadcrumb';
import { BreadcrumbService } from '../breadcrumb.service';
import { NavigationModel } from './models/navigation.model';
import { NavigationContentService } from './services/navigation-content.service';

declare let window: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnDestroy, OnInit {
  private breadcrumbServiceSubscription: Subscription;
  private subscription: Subscription;

  isMigrated = false;
  links: NavigationCategory[];
  navigationHidden = false;
  navigationModel = new NavigationModel();

  constructor(
    private readonly authService: AuthService,
    private readonly navigationContentService: NavigationContentService,
    private readonly navigationService: NavigationService,
    private readonly productsService: ProductsService,
    private readonly transferService: TransferService,
    private readonly breadcrumbService: BreadcrumbService
  ) {
    this.navigationService.navigationModel.subscribe((x) => this.handleNavigationChange(x));
  }

  ngOnDestroy(): void {
    this.breadcrumbServiceSubscription?.unsubscribe();
    this.subscription?.unsubscribe();
  }

  ngOnInit() {
    this.navigationHidden = (window as any).navigationHidden;
    if (this.navigationHidden) {
      return;
    }

    this.subscription = this.authService.userLoaded.subscribe((_userInfo: UserInformationModel | null) => {
      this.transferService.getActiveDossierStatus().subscribe({ next: (dossierStatus) => {

        this.isMigrated = !!this.authService.customerInformation?.magentaVkfe;

        this.links = this.navigationContentService.items(
          this.authService.customerInformation?.noConvergedInfoYet === false,
          this.isMigrated,
          !!dossierStatus,
          this.productsService.getSubscriptionForCustomer()
        );
      }});

      this.breadcrumbServiceSubscription = this.breadcrumbService.breadcrumbChanged.subscribe((crumbs: Breadcrumb[]) => {
        if (this.navigationModel.localNavigation?.backLink && crumbs.length > 1) {
          this.navigationModel.localNavigation.backLink.url = crumbs[crumbs.length - 2].url;
        }
        if (crumbs[crumbs.length - 1]?.displayName) {
          this.navigationModel.title = crumbs[crumbs.length - 1].displayName;
        }
      });

    });
  }

  private handleNavigationChange(model: NavigationModel) {
    this.navigationModel = model;
  }
}
