import { Observable, throwError, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

// See https://www.learnrxjs.io/learn-rxjs/operators/error_handling/retrywhen
export const genericRetryStrategy = ({
  maxRetryAttempts = 3,
  scalingDuration = 1000,
  excludedStatusCodes = []
}: {
  excludedStatusCodes?: number[];
  maxRetryAttempts?: number;
  scalingDuration?: number;
} = {}) => (attempts: Observable<any>) => attempts.pipe(
  mergeMap((error, i) => {
    const retryAttempt = i + 1;
    // if maximum number of retries have been met
    // or response is a status code we don't wish to retry, throw error
    if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find((e) => e === error.status)) {
      return throwError(error);
    }
    // retry after 1s, 2s, etc...
    return timer(retryAttempt * scalingDuration);
  })
);
