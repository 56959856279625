import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { TvSettingViewModel } from 'app/shared/open-api/data-contracts';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { TvService } from '../services/tv.service';

@Injectable()
export class TvResolver implements Resolve<TvSettingViewModel | null> {
  constructor(private tvService: TvService) {}

  resolve(): Observable<TvSettingViewModel | null> {
    return this.tvService.getTvSettingsDetails().pipe(first());
  }
}
