import { CommonModule, DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthGuard } from 'app/auth/auth.guard';
import { LoadingIndicatorComponent } from 'app/core/loading-indicator/loading-indicator.component';
import { FeatureConfigurationService } from 'app/core/services/featureconfiguration.service';
import { SettingsGuard } from 'app/modules/configuration/settings.guard';
import { InternetSpeedFooterComponent } from 'app/modules/shop/internet/internet-speed-footer/internet-speed-footer.component';
import { MaxSepedCalloutComponent } from 'app/modules/shop/internet/max-speed-callout/max-speed-callout.component';
import { ByoTvOverlayComponent } from 'app/modules/shop/television/byo-tv-overlay/byo-tv-overlay.component';
import { ContentService } from 'app/shared/content.service';
import { NavigationLocalViewComponent } from 'app/shared/framework/navigation-local-view/navigation-local-view.component';
import { NavigationViewComponent } from 'app/shared/framework/navigation-view/navigation-view.component';
import { FileSaverModule } from 'ngx-filesaver';
import { DemoGuard } from './demo.guard';
import { HrefToNavigationDirective } from './directives/href-to-navigation.directive';
import { ScrollIntoViewDirective } from './directives/scroll-into-view.directive';
import { TrackingBannerViewDirective } from './directives/tracking-banner-view.directive';
import { TrackingByCategoryDirective } from './directives/tracking-by-category.directive';
import { TrackingHelpDirective } from './directives/tracking-help.directive';
import { TrackingNavDirective } from './directives/tracking-nav.directive';
import { TrackingTileDirective } from './directives/tracking-tile.directive';
import { TrackingViewDirective } from './directives/tracking-view.directive';
import { AccordionComponent } from './framework/accordion/accordion.component';
import { AddressFormComponent } from './framework/address-form/address-form.component';
import { CalloutComponent } from './framework/alerts/callout.component';
import { CardComponent } from './framework/card/card.component';
import { FaqComponent } from './framework/faq/faq.component';
import { ButtonGroupComponent } from './framework/forms/button-group/button-group.component';
import { SelectListComponent } from './framework/forms/select-list/select-list.component';
import { SelectorComponent } from './framework/forms/selector/selector.component';
import { SubmitButtonComponent } from './framework/forms/submit-button/submit-button.component';
import { InfoAlertsComponent } from './framework/info-alerts/info-alerts.component';
import { DatePickerComponent } from './framework/input/date-picker.component';
import { InputWrapperComponent } from './framework/input/input-wrapper.component';
import { LinkListGroupComponent } from './framework/link-list-group/link-list-group.component';
import { MainMenuComponent } from './framework/navigation-view/main-menu/main-menu.component';
import { ModalMenuComponent } from './framework/navigation-view/modal-menu/modal-menu.component';
import { PageHeaderComponent } from './framework/page/page-header/page-header.component';
import { PaginationComponent } from './framework/pagination/pagination.component';
import { PingVideoComponent } from './framework/ping-video/ping-video.component';
import { ShoppingCartComponent } from './framework/shopping-cart/shopping-cart.component';
import { StepperComponent } from './framework/stepper/stepper.component';
import { TimelineComponent } from './framework/timeline/timeline.component';
import { MigratedOrConvergedUserGuard } from './migrated-or-converged-user.guard';
import { MigratedUserGuard } from './migrated-user.guard';
import { NumeralService } from './numeral.service';
import { BillCyclePipe } from './pipes/bill-cycle.pipe';
import { ContentPipe } from './pipes/content.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FormatPipe } from './pipes/format.pipe';
import { KiloBytesConverterPipe } from './pipes/kilobytes-converter.pipe';
import { NumeralCurrencyPipe } from './pipes/numeral-currency.pipe';
import { ProductDescriptionPipe } from './pipes/productdescription.pipe';
import { RemoveDashPipe } from './pipes/removedash.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SetTopBoxNamePipe } from './pipes/settopboxname.pipe';
import { SortbyPipe } from './pipes/sortby.pipe';
import { TrackingService } from './tracking.service';
import { UserGuard } from './user.guard';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, FileSaverModule],
  declarations: [
    FaqComponent,
    PingVideoComponent,
    LoadingIndicatorComponent,

    ButtonGroupComponent,

    SubmitButtonComponent,
    SelectListComponent,
    MaxSepedCalloutComponent,
    InternetSpeedFooterComponent,

    // Framework
    AccordionComponent,
    AddressFormComponent,
    CalloutComponent,
    CardComponent,
    DatePickerComponent,
    InfoAlertsComponent,
    InputWrapperComponent,
    MainMenuComponent,
    ModalMenuComponent,
    NavigationLocalViewComponent,
    NavigationViewComponent,
    PageHeaderComponent,
    PaginationComponent,
    ShoppingCartComponent,
    StepperComponent,
    TimelineComponent,
    LinkListGroupComponent,
    SelectorComponent,

    // Directives
    HrefToNavigationDirective,
    ScrollIntoViewDirective,
    TrackingHelpDirective,
    TrackingNavDirective,
    TrackingTileDirective,
    TrackingByCategoryDirective,
    TrackingViewDirective,
    TrackingBannerViewDirective,

    // Pipes
    SortbyPipe,
    FilterPipe,
    NumeralCurrencyPipe,
    SetTopBoxNamePipe,
    SafeHtmlPipe,
    SafePipe,
    RemoveDashPipe,
    ContentPipe,
    FormatPipe,
    ProductDescriptionPipe,
    BillCyclePipe,
    KiloBytesConverterPipe,

    ByoTvOverlayComponent
  ],
  exports: [
    FaqComponent,
    PingVideoComponent,
    LoadingIndicatorComponent,

    ButtonGroupComponent,
    SubmitButtonComponent,
    SelectListComponent,
    MaxSepedCalloutComponent,
    InternetSpeedFooterComponent,

    // Framework
    AccordionComponent,
    AddressFormComponent,
    CalloutComponent,
    CardComponent,
    DatePickerComponent,
    InfoAlertsComponent,
    InputWrapperComponent,
    MainMenuComponent,
    ModalMenuComponent,
    NavigationLocalViewComponent,
    NavigationViewComponent,
    PageHeaderComponent,
    PaginationComponent,
    ShoppingCartComponent,
    StepperComponent,
    TimelineComponent,
    LinkListGroupComponent,
    SelectorComponent,

    // Directives
    HrefToNavigationDirective,
    ScrollIntoViewDirective,
    TrackingHelpDirective,
    TrackingNavDirective,
    TrackingTileDirective,
    TrackingByCategoryDirective,
    TrackingViewDirective,
    TrackingBannerViewDirective,

    // Pipes
    SortbyPipe,
    FilterPipe,
    NumeralCurrencyPipe,
    RemoveDashPipe,
    SafeHtmlPipe,
    SafePipe,
    SetTopBoxNamePipe,
    ContentPipe,
    FormatPipe,
    ProductDescriptionPipe,
    BillCyclePipe,
    KiloBytesConverterPipe,

    ByoTvOverlayComponent
  ],
  providers: [
    AuthGuard,
    { provide: ContentService, useValue: new ContentService() },
    DatePipe,
    DemoGuard,
    FeatureConfigurationService,
    HttpClient,
    MigratedUserGuard,
    MigratedOrConvergedUserGuard,
    NumeralCurrencyPipe,
    NumeralService,
    SettingsGuard,
    TrackingService,
    UserGuard
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}
