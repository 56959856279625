export class TrackingEventInfoModel {
  trackingCategory: string;
  trackingLabel: string;

  constructor(trackingCategory: string, trackingLabel: string) {
    this.trackingCategory = trackingCategory;
    this.trackingLabel = trackingLabel;
  }
}

/* Google Analytics models */
export class GaEventModel {
  eventAction: string;
  eventCategory: string;
  eventLabel: string;
  eventValue?: number;
  hitType: string;
}

export class GaPageViewModel {
  hitType: string;
  page: string;
}

/*
  These models are mapped in Google Tag Manager, so any breaking change
  in thses models have to be aligned with other parties first (GHG/TMNL Onine).
*/
export class GtmOrderModel {
  customer_id: string;
  order_id: string;
  product_array: GtmOrderProductModel[];
}

export class GtmOrderProductModel {
  category: string;
  id: string;
  name: string;
  option_name: string;
  price: number;
  quantity: number;
  status: string; // 'on'/'off'
}

export class GtmCustomerModel {
  address: GtmAddressModel;
  contact: GtmContactModel;
  customerId: string;
  customerIdHashed: string;
  isAgent: boolean;
  isFmcCustomer: boolean;
  privacy: GtmPrivacyModel;
  products: GtmProductsViewModel;
}

export class GtmAddressModel {
  city: string;
  fullAddress: string;
  fullAddressHashed: string;
  number: number; // eslint-disable-line id-blacklist
  numberExtension: string;
  numberExtensionHashed: string;
  numberHashed: string;
  street: string;
  zipcode: string;
  zipcodeHashed: string;
}

export class GtmContactModel {
  email: string;
  emailHashed: string;
  telephoneNumber: string;
  telephoneNumberHashed: string;
}

export class GtmPrivacyModel {
  offers: PrivacyChannels;
  research: PrivacyChannels;
}

export class PrivacyChannels {
  email: boolean;
  letters: boolean;
  sms: boolean;
  telemarketing: boolean;
}

export class GtmProductsViewModel {
  internetProducts: GtmProduct[];
  tvProducts: GtmProduct[];
  voipProducts: GtmProduct[];
}

export class GtmProduct {
  name: string;
  price: number;
  startdate: string;
}
