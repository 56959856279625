import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from 'app/core/Endpoints';
import { HashingService } from 'app/core/services/hashing.service';
import moment from 'moment';
import { Observable, zip } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { TrackingEventType } from './models/tracking/tracking.eventtype.enum';
import {
  CustomerInformationViewModel,
  PrivacySettingsModel,
  SubscriptionModel,
  SubscriptionOwnedProductModel
} from './open-api/data-contracts';
import {
  GaEventModel,
  GaPageViewModel,
  GtmAddressModel,
  GtmContactModel,
  GtmCustomerModel,
  GtmPrivacyModel,
  GtmProduct,
  GtmProductsViewModel,
  PrivacyChannels,
  TrackingEventInfoModel
} from './tracking.models';
declare global {
  interface Window {
    dataLayer: any[];
    ga: any;
  }
}

@Injectable()
export class TrackingService {
  private get dataLayer(): any[] {
    // If GA is not added, return array to prevent errors;
    return window.dataLayer || [];
  }

  private get ga(): any {
    return window.ga;
  }

  readonly measurementId = 'GTM-PX48MK3';

  constructor(private readonly httpClient: HttpClient) {}

  clickEvent(category: string, label: string) {
    this.sendEvent(category, TrackingEventType.Click, label);
  }

  getPrivacySettings(): Observable<PrivacySettingsModel | null> {
    return this.httpClient.get<PrivacySettingsModel>(Endpoints.accountPrivacy).pipe(first());
  }

  getSubscriptionForCustomer(): Observable<SubscriptionModel> {
    return this.httpClient.get<SubscriptionModel>(Endpoints.subscription).pipe(
      first(),
      map((s) => (!s ? ({} as SubscriptionModel) : s))
    );
  }
  joinTrackingLabels(labels: string[]): string {
    return labels.join(' + ');
  }

  loginSuccessEvent(account: CustomerInformationViewModel, isAgent: boolean) {
    const pushDataAndSendEvt = (subscription: SubscriptionModel, gtmPrivacyModel: GtmPrivacyModel) => {
      const gtmProductsViewModel: GtmProductsViewModel = this.mapToGtmProductsViewModel(subscription);
      const gtmCustomerModel = this.mapToGtmCustomerModel(account, gtmPrivacyModel, gtmProductsViewModel, isAgent);
      this.pushCustomerData(gtmCustomerModel);
      this.sendEvent('AC - Inloggen', TrackingEventType.Success, 'Inloggen');
    };

    zip(this.getSubscriptionForCustomer(), this.getPrivacySettings()).subscribe({
      next: ([subscription, privacySettings]) => {
        const gtmPrivacyModel = this.mapPrivacySettingsModelToGtmPrivacyModel(privacySettings);
        pushDataAndSendEvt(subscription, gtmPrivacyModel);
      }
    });
  }

  logoutSuccessEvent() {
    this.sendEvent('AC - Uitloggen', TrackingEventType.Success, 'Uitloggen');
  }

  pageView(): void {
    this.sendPageView(location.href);
  }

  removeTrackingHeaders(headers: HttpHeaders): HttpHeaders {
    return headers.delete('trackingCategory').delete('trackingLabel');
  }

  toTrackingApiHeaders(eventInfo: TrackingEventInfoModel): Headers {
    return new Headers([
      ['trackingCategory', eventInfo.trackingCategory],
      ['trackingLabel', eventInfo.trackingLabel]
    ]);
  }

  toTrackingHeaders(eventInfo: TrackingEventInfoModel): HttpHeaders {
    return new HttpHeaders()
      .append('trackingCategory', eventInfo.trackingCategory)
      .append('trackingLabel', eventInfo.trackingLabel);
  }

  trackChangeError(eventInfo: TrackingEventInfoModel) {
    this.sendEvent(eventInfo.trackingCategory, TrackingEventType.Error, eventInfo.trackingLabel);
  }

  trackChangeSuccess(eventInfo: TrackingEventInfoModel) {
    this.sendEvent(eventInfo.trackingCategory, TrackingEventType.Success, eventInfo.trackingLabel);
  }

  trackedGetAsBlob(url: string, trackingInfo: TrackingEventInfoModel, httpParams?: HttpParams | undefined): Observable<Blob> {
    return this.httpClient.get(url, {
      params: httpParams,
      observe: 'body',
      responseType: 'blob',
      headers: this.toTrackingHeaders(trackingInfo)
    });
  }

  trackedPost<T>(url: string, model: any, trackingInfo: TrackingEventInfoModel): Observable<T> {
    return this.httpClient.post<T>(url, model, {
      observe: 'body',
      responseType: 'json',
      headers: this.toTrackingHeaders(trackingInfo)
    });
  }

  trackedPostAsText(url: string, model: any, trackingInfo: TrackingEventInfoModel): Observable<string> {
    return this.httpClient.post(url, model, {
      observe: 'body',
      responseType: 'text',
      headers: this.toTrackingHeaders(trackingInfo)
    });
  }

  trackedPut<T>(url: string, model: any, trackingInfo: TrackingEventInfoModel): Observable<T> {
    return this.httpClient.put<T>(url, model, {
      observe: 'body',
      responseType: 'json',
      headers: this.toTrackingHeaders(trackingInfo)
    });
  }

  trackedPutAsText(url: string, model: any, trackingInfo: TrackingEventInfoModel): Observable<string> {
    return this.httpClient.put(url, model, {
      observe: 'body',
      responseType: 'text',
      headers: this.toTrackingHeaders(trackingInfo)
    });
  }

  trackerName(): string {
    if (!this.ga?.getAll) {
      return '';
    }

    const trackers = this.ga.getAll();
    let trackerName = '';
    if (trackers && trackers[0]) {
      trackerName = trackers[0]?.get('name');
    }

    return trackerName ? trackerName + '.' : '';
  }

  viewEvent(category: string, label: string) {
    this.sendEvent(category, TrackingEventType.View, label);
  }

  private mapPrivacySettingsModelToGtmPrivacyModel(privacySettingsModel: PrivacySettingsModel | null): GtmPrivacyModel {
    function findBy(property: boolean | undefined | null, channels: string[] | undefined | null, channel: string) {
      return (property === true && channels?.some((c) => c === channel)) ?? false;
    }

    return {
      offers: {
        email: findBy(privacySettingsModel?.commercialOfferings, privacySettingsModel?.commercialOfferingsChannels, 'Email'),
        letters: findBy(privacySettingsModel?.commercialOfferings, privacySettingsModel?.commercialOfferingsChannels, 'Letter'),
        sms: findBy(privacySettingsModel?.commercialOfferings, privacySettingsModel?.commercialOfferingsChannels, 'Sms'),
        telemarketing: findBy(
          privacySettingsModel?.commercialOfferings,
          privacySettingsModel?.commercialOfferingsChannels,
          'Phone'
        )
      } as PrivacyChannels,
      research: {
        email: findBy(privacySettingsModel?.useResearchData, privacySettingsModel?.useResearchDataChannels, 'Email'),
        letters: findBy(privacySettingsModel?.useResearchData, privacySettingsModel?.useResearchDataChannels, 'Letter'),
        sms: findBy(privacySettingsModel?.useResearchData, privacySettingsModel?.useResearchDataChannels, 'Sms'),
        telemarketing: findBy(privacySettingsModel?.useResearchData, privacySettingsModel?.useResearchDataChannels, 'Phone')
      } as PrivacyChannels
    } as GtmPrivacyModel;
  }

  private mapProducts(products: GtmProduct[]): any {
    const result: { name: string; price: number; startdate: string }[] = [];
    if (products) {
      products.forEach((p) => {
        result.push({
          name: p.name,
          price: p.price,
          startdate: p.startdate
        });
      });
    }
    return result;
  }

  private mapToGtmCustomerModel(
    account: CustomerInformationViewModel,
    privacy: GtmPrivacyModel,
    products: GtmProductsViewModel,
    isAgent: boolean
  ): GtmCustomerModel {
    const fullAddress = `${account.primaryAddress?.postalCode}${account.primaryAddress?.houseNumber}${account.primaryAddress?.houseNumberAddition}`;
    const telephoneNumber = account.contactInformation?.find((x) => (x.contactType as any) === 'PhoneNumber')?.value;

    const gtmCustomerModel = {
      customerId: account.customerId,
      address: {
        city: account.primaryAddress?.city,
        fullAddress,
        number: account.primaryAddress?.houseNumber, // eslint-disable-line id-blacklist
        numberExtension: account.primaryAddress?.houseNumberAddition,
        street: account.primaryAddress?.street,
        zipcode: account.primaryAddress?.postalCode
      } as GtmAddressModel,
      contact: {
        email: account.personInformation?.primaryEmailAddress,
        telephoneNumber
      } as GtmContactModel,
      isAgent,
      isFmcCustomer: account.isSubscribedAtFixed,
      privacy,
      products
    } as GtmCustomerModel;

    this.setHashedProperties(gtmCustomerModel);

    return gtmCustomerModel;
  }

  private mapToGtmProducts(...products: (SubscriptionOwnedProductModel | undefined)[]): GtmProduct[] {
    return products
      .filter((p) => p !== null)
      .map(
        (p) =>
          ({
            name: p!.displayName,
            price: p!.price || 0,
            startdate: p!.inServiceDate ? moment(p!.inServiceDate).format('dd-MM-yyyy') : moment(p!.wishDate).format('dd-MM-yyyy')
          } as GtmProduct)
      );
  }

  private mapToGtmProductsViewModel(subscription: SubscriptionModel): GtmProductsViewModel {
    return {
      internetProducts: this.mapToGtmProducts(subscription.currentInternet, ...(subscription.currentWifiExtenders || [])),
      tvProducts: this.mapToGtmProducts(
        subscription.currentTv,
        ...(subscription.currentPremiumPackages || []),
        ...(subscription.currentThemePackages || []),
        ...(subscription.currentSettopBoxes || [])
      ),
      voipProducts: this.mapToGtmProducts(subscription.currentPrimaryVoipProduct, subscription.currentSecondaryVoipProduct)
    } as GtmProductsViewModel;
  }

  private pushCustomerData(customerInfo: GtmCustomerModel) {
    const data: any = {
      userId: {
        unhashed: customerInfo.customerId,
        hashed: customerInfo.customerIdHashed
      },
      address: {
        full_address: {
          unhashed: customerInfo.address.fullAddress,
          hashed: customerInfo.address.fullAddressHashed
        },
        postcode: {
          unhashed: customerInfo.address.zipcode,
          hashed: customerInfo.address.zipcodeHashed
        },
        street: customerInfo.address.street,
        // eslint-disable-next-line id-blacklist
        number: {
          unhashed: customerInfo.address.number,
          hashed: customerInfo.address.numberHashed
        },
        number_extension: {
          unhashed: customerInfo.address.numberExtension,
          hashed: customerInfo.address.numberExtensionHashed
        },
        city: customerInfo.address.city
      },
      tel: {
        unhashed: customerInfo.contact.telephoneNumber,
        hashed: customerInfo.contact.telephoneNumberHashed
      },
      email: {
        unhashed: customerInfo.contact.email,
        hashed: customerInfo.contact.emailHashed
      },
      active_products: {
        internet: this.mapProducts(customerInfo.products.internetProducts),
        tv: this.mapProducts(customerInfo.products.tvProducts),
        bellen: this.mapProducts(customerInfo.products.voipProducts)
      },
      privacy_level_offers: customerInfo.privacy.offers,
      privacy_level_research: customerInfo.privacy.research,
      agent_session: customerInfo.isAgent,
      fmc_user: customerInfo.isFmcCustomer
    };

    this.dataLayer.push({
      event: 'user_data_loaded',
      user_data_loaded: true,
      user_data: data
    });
  }

  private sendEvent(category: string, eventType: TrackingEventType, label: string, value?: number) {
    const model = new GaEventModel();
    model.eventAction = TrackingEventType[eventType];
    model.eventCategory = category;
    model.eventLabel = label;
    model.eventValue = value;
    model.hitType = 'event';
    this.sendToGa(model);
  }

  private sendPageView(path: string) {
    const model = new GaPageViewModel();
    model.hitType = 'pageview';
    model.page = path;
    this.sendToGa(model);
  }

  private sendToGa(model: GaEventModel | GaPageViewModel): void {
    if (!this.ga) {
      // Ignore the hit if ga is not loaded yet. This can happen on the first pageview after
      // the navigation ended. By loading th ga scripts an initial pageview is already send.
      return;
    }

    const trackerName = this.trackerName();

    if (model instanceof GaPageViewModel) {
      this.ga(`${trackerName}send`, model.hitType, model.page);
    } else if (model instanceof GaEventModel) {
      if (!model.eventValue) {
        this.ga(`${trackerName}send`, model.hitType, model.eventCategory, model.eventAction, model.eventLabel, {
          nonInteraction: true
        });
      } else {
        this.ga(`${trackerName}send`, model.hitType, model.eventCategory, model.eventAction, model.eventLabel, model.eventValue, {
          nonInteraction: true
        });
      }
    }
  }

  private setHashedProperties(customerModel: GtmCustomerModel) {
    const keysToHash = ['customerId', 'fullAddress', 'number', 'numberExtension', 'zipcode', 'email', 'telephoneNumber'];

    const addHashedProperties = (model: any) => {
      Object.keys(model).forEach((key) => {
        if (keysToHash.includes(key)) {
          Object.defineProperty(model, `${key}Hashed`, {
            value: HashingService.createDcfHash(model[key])
          });
        }
      });
    };

    addHashedProperties(customerModel);
    addHashedProperties(customerModel.address);
    addHashedProperties(customerModel.contact);
  }
}
