import { Injectable } from '@angular/core';

/**
 * A simple service with methods related to urls
 */
@Injectable({
  providedIn: 'root'
})
export class UrlService {
  setLocationHref(url: string) {
    window.location.href = url;
  }
}
