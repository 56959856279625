import { AfterViewInit, Component, Input } from '@angular/core';
import { NavigationLink } from 'app/core/navigation/models/navigation-link';

declare let window: any;

@Component({
  selector: 'app-navigation-local-view',
  templateUrl: './navigation-local-view.component.html',
  styleUrls: ['./navigation-local-view.component.scss']
})
export class NavigationLocalViewComponent implements AfterViewInit {
  @Input() backLink: NavigationLink | null;
  @Input() isSubMenu = false;
  @Input() quickLink: NavigationLink | null;
  @Input() title: string;

  isMagentaUrl(url: string): boolean {
    return url.indexOf((window as any).magentaBaseUrl) === 0;
  }

  ngAfterViewInit(): void {
    window.TMobileDesignSystem.initSubNav();
  }
}
