import { LoadingIndicatorService } from './loading-indicator.service';

export class PageBase {
  loadCompleted = false;
  loadFailed = false;
  navoff = window.location.search?.includes('nav=off');
  pageLoading = false;

  constructor(
    protected readonly loadingIndicatorService: LoadingIndicatorService) {
  }

  setPageLoadComplete() {
    this.loadCompleted = true;
    this.pageLoading = false;
    this.loadingIndicatorService.setPageLoadComplete();
  }

  setPageLoadFailed() {
    this.loadFailed = true;
    this.pageLoading = false;
    this.loadingIndicatorService.setPageLoadFailed();
  }

  setPageLoading() {
    this.pageLoading = true;
    this.loadingIndicatorService.setPageLoading();
  }
}
