import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { CloudRecordingModel } from 'app/shared/open-api/data-contracts';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { TelevisionService } from '../services/television.service';

@Injectable()
export class CloudRecordingResolver implements Resolve<CloudRecordingModel | null> {
  constructor(private televisionService: TelevisionService) {}

  resolve(): Observable<CloudRecordingModel | null> {
    return this.televisionService.getAvailableCloudRecordingProducts().pipe(first());
  }
}
