// eslint-disable-next-line id-blacklist
interface String {
  equals(compareValue: string, caseInsensitive: boolean): boolean;
  format(...replacements: (string | null)[]): string;
  toFirstLetterLowerCase(): string;
}

/**
 * Adds a format method to the string prototype.
 * Enables a string format as we are used to in C#, "Hi {0}".format("all") = "Hi all"
 */
if (!String.prototype.format) {
  String.prototype.format = function () {
    const args = arguments;
    return this.replace(/{(\d+)}/g, (match, num) => typeof args[num] !== 'undefined' ? args[num] : match);
  };
}

/**
 * Adds an equals method to the string prototype that can check whether two strings equal.
 * Supports case sensitivity.
 */
if (!String.prototype.equals) {
  String.prototype.equals = function (compareValue: string, caseInsensitive: boolean) {
    if (caseInsensitive) {
      return this.toUpperCase() === compareValue.toUpperCase();
    }
    return this === compareValue;
  };
}

/**
 * Adds an toFirstLetterLowerCase method to the string prototype
 * Enables a transform for the first letter of a string to lower case.
 */
if (!String.prototype.toFirstLetterLowerCase) {
  String.prototype.toFirstLetterLowerCase = function () {
    if (!this) {
      return this
    }
    return this?.charAt(0)?.toLowerCase() + this.slice(1);
  };
}
