<ng-container *ngIf="!navigationHidden">
  <app-navigation-view
    *ngIf="navigationModel.visibleMainNav"
    [links]="links"
  >
      <app-nav-item-account *ngIf="isMigrated" custom-links-top-level-1></app-nav-item-account>
      <app-nav-item-account *ngIf="isMigrated" custom-links-top-level-2></app-nav-item-account>
      <app-nav-item-logout custom-links-bottom-level-1></app-nav-item-logout>
  </app-navigation-view>

  <app-navigation-local-view
    *ngIf="navigationModel.localNavigation && !navigationModel.visibleMainNav"
    [backLink]="navigationModel.localNavigation.backLink"
    [quickLink]="navigationModel.localNavigation.quickLink"
    [title]="navigationModel.title"
  ></app-navigation-local-view>

  <app-navigation-local-view
    *ngIf="navigationModel.localNavigation && navigationModel.visibleMainNav"
    [backLink]="navigationModel.localNavigation.backLink"
    [isSubMenu]="true"
    [quickLink]="navigationModel.localNavigation.quickLink"
    [title]="navigationModel.title"
  ></app-navigation-local-view>
</ng-container>