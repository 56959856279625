import { Component } from '@angular/core';
import { RequestCache } from 'app/core/request-cache';

@Component({
  templateUrl: './load-error.component.html'
})
export class LoadErrorComponent {
  constructor(requestCache: RequestCache) {
    requestCache.clear();
  }
}
