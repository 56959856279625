import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TvSettingViewModel } from 'app/shared/open-api/data-contracts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Endpoints } from '../Endpoints';

@Injectable()
export class TvService {
  constructor(private readonly httpCacheClient: HttpClient) {}

  getTvSettingsDetails(): Observable<TvSettingViewModel | null> {
    return this.httpCacheClient.get<TvSettingViewModel>(Endpoints.tvSettings).pipe(
      map((response) => {
        if (!response) {
          return null;
        }

        return response;
      })
    );
  }
}
