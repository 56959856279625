import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ConvergedStatusViewModel } from 'app/shared/open-api/data-contracts';
import { Observable } from 'rxjs';
import { ConvergedService } from '../services/converged.service';

/** The converged status resolver uses V2 of the Converged service API to retrieve the customers's converged status. */
@Injectable()
export class ConvergedStatusResolver implements Resolve<ConvergedStatusViewModel | null> {
  constructor(private readonly convergedService: ConvergedService) {}

  resolve(): Observable<ConvergedStatusViewModel | null> {
    return this.convergedService.customerIsConvergedV2();
  }
}
