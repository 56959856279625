import { inject } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { HouseholdRouteConstants } from 'app/shared/constants/household-route-constants';
import { SubscriptionModel } from 'app/shared/open-api/data-contracts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationCategory, NavigationLink } from '../models/navigation-link';

declare let window: any;

export class NavigationContentService {
  private get magentaBaseUrl() {
    return (window as any).magentaBaseUrl;
  }
  
  private authService = inject(AuthService);
  private mobileNavLinks: MobileNavLinks = new MobileNavLinks(this.magentaBaseUrl);
  
  items(
    isConverged: boolean,
    isMigrated: boolean,
    hasTransferDossier: boolean,
    subscription: Observable<SubscriptionModel>
  ): NavigationCategory[] {
    
    ThuisNavLinks.usageCostsLink.visible = !!this.authService.customerInformation?.magentaVkfe;
    if (isMigrated && isConverged) {
      return [
        {
          menuLevel: 0,
          links: [
            ThuisNavLinks.dashboardLink,
            ThuisNavLinks.invoicesLink,
            ThuisNavLinks.subscriptionLink,
            { text: 'Meer', url: '', icon: 'icon-more', visible: true } as NavigationLink
          ]
        } as NavigationCategory,
        {
          name: 'Algemeen',
          menuLevel: 1,
          links: [
            ThuisNavLinks.ordersLink,
            ThuisNavLinks.usageCostsLink,
            ThuisNavLinks.householdLink(subscription.pipe(map((s) => !!s.currentInternet))),
            hasTransferDossier ? ThuisNavLinks.transferLink : null
          ]
        } as NavigationCategory,
        {
          name: 'Instellingen',
          menuLevel: 1,
          links: [
            this.mobileNavLinks.myInvoicesLink,
            ThuisNavLinks.subscriptionSubLink,
            ThuisNavLinks.settingsLink(),
            { text: 'Persoonlijke gegevens', url: '', visible: true } as NavigationLink
          ]
        } as NavigationCategory,
        {
          name: 'Service',
          menuLevel: 1,
          links: [ThuisNavLinks.interuptionsLink, this.mobileNavLinks.customerServiceLink, this.mobileNavLinks.shopsLink]
        } as NavigationCategory,
        {
          name: 'Persoonsgegevens',
          menuLevel: 2,
          links: [
            this.mobileNavLinks.myNameLink,
            ThuisNavLinks.addressLink,
            this.mobileNavLinks.myPhoneLink
          ]
        } as NavigationCategory,
        {
          name: 'Beveiliging',
          menuLevel: 2,
          links: [this.mobileNavLinks.myPasswordLink, this.mobileNavLinks.my2FALink]
        } as NavigationCategory,
        {
          name: '',
          menuLevel: 2,
          links: [this.mobileNavLinks.myPrivacyLink]
        } as NavigationCategory
      ];
    } else if (isMigrated && !isConverged) {
      return [
        {
          menuLevel: 0,
          links: [
            ThuisNavLinks.ordersLink,
            hasTransferDossier ? ThuisNavLinks.transferLink : null,
            this.mobileNavLinks.myPersonalDataLink,
            { text: 'Meer', url: '', icon: 'icon-more', visible: true } as NavigationLink
          ]
        } as NavigationCategory,
        {
          name: 'Service',
          menuLevel: 1,
          links: [ThuisNavLinks.interuptionsLink, this.mobileNavLinks.customerServiceLink, this.mobileNavLinks.shopsLink]
        } as NavigationCategory
      ];
    } else if (!isMigrated && isConverged) {
      return [
        {
          menuLevel: 0,
          links: [
            ThuisNavLinks.dashboardLink,
            ThuisNavLinks.invoicesLinkOld,
            ThuisNavLinks.subscriptionOldLink,
            { text: 'Meer', url: '', icon: 'icon-more', visible: true } as NavigationLink
          ]
        } as NavigationCategory,
        {
          name: '',
          menuLevel: 1,
          links: [
            ThuisNavLinks.ordersLink,
            ThuisNavLinks.settingsCommonLink,
            ThuisNavLinks.usageCostsLink,
            ThuisNavLinks.myDataLink,
            ThuisNavLinks.householdLink(subscription.pipe(map((s) => !!s.currentInternet))),
            hasTransferDossier ? ThuisNavLinks.transferLink : null,
            ThuisNavLinks.interuptionsLink
          ]
        } as NavigationCategory,
        {
          name: 'Service',
          menuLevel: 1,
          links: [
            this.mobileNavLinks.customerServiceLink,
            this.mobileNavLinks.chatLink,
            this.mobileNavLinks.communityLink,
            this.mobileNavLinks.shopsLink
          ]
        } as NavigationCategory
      ];
    } else if (!isMigrated && !isConverged) {
      return [
        {
          menuLevel: 0,
          links: [
            ThuisNavLinks.ordersLink,
            hasTransferDossier ? ThuisNavLinks.transferLink : null,
            ThuisNavLinks.myDataLink,
            { text: 'Meer', url: '', icon: 'icon-more', visible: true } as NavigationLink
          ]
        } as NavigationCategory,
        {
          name: 'Service',
          menuLevel: 1,
          links: [
            this.mobileNavLinks.customerServiceLink,
            this.mobileNavLinks.chatLink,
            this.mobileNavLinks.communityLink,
            this.mobileNavLinks.shopsLink
          ]
        } as NavigationCategory
      ];
    }

    return [];
  }

  itemsForTransfer(userReference: string): NavigationCategory[] {
    return [
      {
        menuLevel: 0,
        links: [
          userReference ? new NavigationLink('Overstappen', `/overstappen-afronden/${userReference}`, 'icon-info') : null,
          { text: 'Meer', url: '', icon: 'icon-more', visible: true } as NavigationLink
        ]
      } as NavigationCategory,
      {
        name: 'Service',
        menuLevel: 1,
        links: [
          this.mobileNavLinks.customerServiceLink,
          this.mobileNavLinks.chatLink,
          this.mobileNavLinks.communityLink,
          this.mobileNavLinks.shopsLink
        ]
      } as NavigationCategory
    ];
  }
}

class ThuisNavLinks {
  static get magentaBaseUrl() {
    return (window as any).magentaBaseUrl;
  }

  static get settings2MagentaFeature() {
    return (window as any).settings2MagentaFeature;
  }

  static addressLink = new NavigationLink('Adres', '/mijn-gegevens/adresgegevens');
  static dashboardLink = new NavigationLink('Overzicht', '/dashboard', 'icon-grid');
  static interuptionsLink = new NavigationLink('Actuele storingen', '/storingen');
  static invoicesLink = new NavigationLink('Facturen', '/rekeningen', 'icon-invoice');
  static invoicesLinkOld = new NavigationLink('Rekeningen', '/rekeningen', 'icon-invoice');
  static myDataLink = new NavigationLink('Mijn gegevens', '/mijn-gegevens', 'icon-manager');
  static ordersLink = new NavigationLink('Bestellingen', '/bestellingen', 'icon-delivery');
  static settingsCommonLink = new NavigationLink('Instellingen', '/instellingen/tv');
  static subscriptionLink = new NavigationLink('Abonnement', '/abonnementen', 'icon-store');
  static subscriptionOldLink = new NavigationLink('Abonnementen', '/abonnementen', 'icon-sim-card');
  static subscriptionSubLink = new NavigationLink('Abonnement & Instellingen', '/abonnementen');
  static transferLink = new NavigationLink('Overstappen', '/overstappen', 'icon-info');
  static usageCostsLink = new NavigationLink('Gebruikskosten', '/gebruikskosten/bellen');
  
  static householdLink(visibleObs: Observable<boolean>): NavigationLink {
    return new NavigationLink('Klantvoordeel', `/${HouseholdRouteConstants.householdRoot}`, 'icon-house', undefined, visibleObs);
  }

  static settingsLink(): NavigationLink {
    const url = this.settings2MagentaFeature ? `${this.magentaBaseUrl}/my/thuis/instellingen` : '/instellingen';
    return new NavigationLink('Instellingen', url, undefined, 'Instellingen');
  }
}

class MobileNavLinks {
  chatLink: NavigationLink = new NavigationLink('Chat', `${this.magentaBaseUrl}/service#contact`);
  communityLink: NavigationLink = new NavigationLink('Community', 'https://community.odido.nl/t-mobile-thuis-489');
  customerServiceLink: NavigationLink = new NavigationLink('Service', `${this.magentaBaseUrl}/service#contact`);
  my2FALink: NavigationLink = new NavigationLink('Tweestapsverificatie', `${this.magentaBaseUrl}/my/tweestapsverificatie`);
  myContactLink: NavigationLink = new NavigationLink('Contact', `${this.magentaBaseUrl}/my/abonnement-overzicht`);
  myInvoicesLink: NavigationLink = new NavigationLink(
    'Betaalgegevens & factuurinstellingen',
    `${this.magentaBaseUrl}/my/betalingsdetails`
  );
  myNameLink: NavigationLink = new NavigationLink('Naam', `${this.magentaBaseUrl}/my/naam`);
  myPasswordLink: NavigationLink = new NavigationLink('Wachtwoord', `${this.magentaBaseUrl}/my/wachtwoord`);
  myPersonalDataLink: NavigationLink = new NavigationLink(
    'Persoonlijke gegevens',
    `${this.magentaBaseUrl}/my/abonnement-overzicht`,
    'icon-manager'
  );
  myPhoneLink: NavigationLink = new NavigationLink('Telefoonnummer', `${this.magentaBaseUrl}/my/telefoonnummer`);
  myPrivacyLink: NavigationLink = new NavigationLink('Privacyinstellingen', `${this.magentaBaseUrl}/my/privacy-instellingen`);
  shopsLink: NavigationLink = new NavigationLink('Winkels', `${this.magentaBaseUrl}/winkels`);

  constructor(public magentaBaseUrl: string) {}
}
