import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { Breadcrumb } from './breadcrumb/breadcrumb';
import { BreadcrumbService } from './breadcrumb.service';
import { LocalNavigationModel } from './navigation/models/local-navigation.model';
import { NavigationLink } from './navigation/models/navigation-link';
import { NavigationModel } from './navigation/models/navigation.model';

@Injectable()
export class NavigationService {
  navigationModel: BehaviorSubject<NavigationModel> = new BehaviorSubject<NavigationModel>(new NavigationModel());

  constructor(readonly router: Router, private readonly breadCrumbService: BreadcrumbService) {
    this.setNavigationFromSnapshot(
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.router.routerState.root.snapshot)
      )
    );

    this.setNavigationFromSnapshot(of(this.router.routerState.root.snapshot).pipe(first()));
  }

  private setNavigation(breadcrumbs: Breadcrumb[], showMainMenu: boolean, showLocalMenu: boolean, hideTitle?: boolean): void {
    if (breadcrumbs.length === 0) {
      this.navigationModel.next(new NavigationModel());
    } else {
      const crumb = breadcrumbs[breadcrumbs.length - 1];

      let parentCrumb: Breadcrumb | null = null;
      if (breadcrumbs.length > 1) {
        parentCrumb = breadcrumbs[breadcrumbs.length - 2];
      }

      const model = {
        hideTitle: hideTitle === true,
        title: crumb.displayName,
        visibleMainNav: showMainMenu !== false,
        localNavigation: null
      } as NavigationModel;

      if (showLocalMenu) {
        model.localNavigation = !showLocalMenu
          ? null
          : ({
            backLink: parentCrumb ? new NavigationLink(parentCrumb.displayName, parentCrumb.url, '') : null
          } as LocalNavigationModel);
      }

      this.navigationModel.next(model);
    }
  }

  private setNavigationFromSnapshot(snapshot: Observable<ActivatedRouteSnapshot>) {
    snapshot
      .pipe(
        map((event) => {
          while (event.firstChild) {
            event = event.firstChild;
          }
          return event;
        })
      )
      .subscribe((event) => {
        const crumbs = this.breadCrumbService.getCrumbs();
        this.setNavigation(crumbs, event.data['showMainMenu'], event.data['showSubMenu'], event.data['hideTitle']);
      });
  }
}
