import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { LoadingIndicatorService } from 'app/core/loading-indicator.service';

@Component({
  selector: 'app-error-status',
  templateUrl: './error-status.component.html',
  styleUrls: ['./error-status.component.scss']
})
export class ErrorStatusComponent implements OnInit {
  @Input() description: string;
  @Input() header: string;
  @Input() imageResource: string;

  showMenu: boolean;

  constructor(private readonly loadingIndicatorService: LoadingIndicatorService, private readonly authService: AuthService) {}

  ngOnInit(): void {
    this.loadingIndicatorService.setPageLoadComplete();
    this.showMenu = this.authService.customerInformation !== null && !this.authService.customerInformation.noConvergedInfoYet;
  }
}
