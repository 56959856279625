import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureConfigViewModel } from 'app/shared/open-api/data-contracts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Endpoints } from '../Endpoints';

@Injectable()
export class FeatureConfigurationService {
  constructor(private readonly httpClient: HttpClient) {}

  get featureConfiguration(): Observable<FeatureConfigViewModel> {
    return this.httpClient
      .get<FeatureConfigViewModel>(Endpoints.features)
      .pipe(map((fc) => (!!fc ? fc : ({} as FeatureConfigViewModel))));
  }
}
