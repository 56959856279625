import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ShopWifiExtenderProductsModel } from 'app/shared/open-api/data-contracts';
import { Observable } from 'rxjs';
import { ProductsService } from '../services/products.service';

/** Returns the current and available products for the current customer. */
@Injectable()
export class AvailableWifiExtenderProductsResolver implements Resolve<ShopWifiExtenderProductsModel | null> {
  constructor(private readonly productsService: ProductsService) {}

  resolve(): Observable<ShopWifiExtenderProductsModel | null> {
    return this.productsService.getAvailableWifiExtenderProducts();
  }
}
