import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddressLookupResponse } from 'app/shared/open-api/data-contracts';
import { Observable } from 'rxjs';
import { Endpoints } from '../Endpoints';

@Injectable()
export class AddressLookupService {
  constructor(private readonly httpClient: HttpClient) {}

  getAddressLookup(postalCode: string, houseNumber: string): Observable<AddressLookupResponse> {
    return this.httpClient.get<AddressLookupResponse>(Endpoints.addressLookup.format(postalCode, houseNumber));
  }
}
