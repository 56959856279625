import { Component, Input } from '@angular/core';
import { NavigationLink } from 'app/core/navigation/models/navigation-link';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent {
  @Input() links: NavigationLink[];
}
