import { Injectable } from '@angular/core';
import { BrowserStorage } from './browserstorage.service';

/**
 * Use SessionStorage to store session local data
 */
@Injectable({
  providedIn: 'root'
})

export class SessionStorage extends BrowserStorage {

  getPrefix(): string {
    return `browser-storage.${window.location.hostname}.`;
  }

  getStorage(): Storage {
    return sessionStorage;
  }
}
