import { Component } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'app-nav-item-logout',
  templateUrl: './navigation-item-logout.component.html',
  styleUrls: ['./navigation-item-logout.component.scss']
})
export class NavigationItemLogoutComponent {
  isLockedAgent = false;

  constructor(private readonly authService: AuthService) {}

  logout(): void {
    if (!this.authService.startLogout()) {
      this.isLockedAgent = true;
    }
  }
}
