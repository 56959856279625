import { Component, Input, OnInit } from '@angular/core';
import { NavigationCategory } from 'app/core/navigation/models/navigation-link';

declare let $: any;

@Component({
  selector: 'app-modal-menu',
  templateUrl: './modal-menu.component.html',
  styleUrls: ['./modal-menu.component.scss']
})
export class ModalMenuComponent implements OnInit {
  @Input() level: number;
  @Input() linkGroups: NavigationCategory[];
  modalMenuId: string;
  modalSubMenuRef: string;
  @Input() title: string;

  closeParentModal() {
    if (this.level > 1) {
      $(`#modal-menu-${this.level - 1} .modal-close-button`).click();
    }
  }

  ngOnInit(): void {
    this.modalMenuId = `modal-menu-${this.level}`;
    this.modalSubMenuRef = `#modal-menu-${this.level + 1}`;
  }
}
