<div custom-links-bottom>
  <ul class="list-group-inset">
    <li *ngIf="isLockedAgent">
      <b class="text-magenta" [innerHTML]="'close-tab-to-logout-text' | content"></b>
    </li>
    <li *ngIf="!isLockedAgent" class="is-clickable">
      <a class="flex align-items-center justify-content-between" [appTrackingNav]="'Uitloggen'" (click)="logout()">
        <div>{{ 'logout' | content }}</div>
        <span class="icon-arrow-right"></span>
      </a>
    </li>
  </ul>
</div>
