import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OptOutCommand, OptOutResponse } from 'app/shared/open-api/data-contracts';
import { Observable } from 'rxjs';
import { Endpoints } from '../Endpoints';

@Injectable()
export class OptOutService {
  constructor(private readonly httpClient: HttpClient) {}

  getConsentV2(userReference: string): Observable<OptOutResponse | null> {
    return this.httpClient.get<OptOutResponse>(Endpoints.optOutV2.format(userReference));
  }

  saveConsentV2(consent: OptOutCommand) {
    return this.httpClient.put<any>(Endpoints.optOutPostV2, consent);
  }
}
