<!-- Main Menu -->
<app-main-menu [links]="mainMenuLinks"></app-main-menu>

<!-- Modal Menu -->
<app-modal-menu [level]=1 [linkGroups]="modalMenuLinks" [title]="modalMenuTitle" >

  <ng-container custom-links-top>
    <ng-content select="[custom-links-top-level-1]"></ng-content>
  </ng-container>

  <ng-container custom-links-bottom>
    <ng-content select="[custom-links-bottom-level-1]"></ng-content>
  </ng-container>

</app-modal-menu>

<!-- Modal Sub Menu -->
<app-modal-menu [level]=2 [linkGroups]="modalSubMenuLinks" [title]="modalSubMenuTitle" >
  
  <ng-container custom-links-top>
    <ng-content select="[custom-links-top-level-2]"></ng-content>
  </ng-container>

</app-modal-menu>
