import { Pipe, PipeTransform } from '@angular/core';
import { NumeralService } from '../numeral.service';

@Pipe({
  name: 'numeralCurrency'
})
export class NumeralCurrencyPipe implements PipeTransform {
  constructor(private numeralService: NumeralService) {}

  /**
   * Formats currency
   * Defaults to symbol ('€ ') 0.00
   * @param value the value to format
   * @param format currency formats of http://numeraljs.com/#format
   */
  transform(value: any, format: string = '$-0.00'): string {
    return this.numeralService.format(value, format);
  }
}
