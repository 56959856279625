export enum OrderTypes {
  Unknown = 0,
  Initial = 1,
  Cease = 2,
  Move = 3,
  ContractRenewal = 4,
  ProductAdd = 5,
  ProductDelete = 6,
  ProductChange = 7,
  NetworkMigration = 8
}
