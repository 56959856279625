import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';

@Injectable()
export class MigratedOrConvergedUserGuard implements CanActivate {
  constructor(private readonly authService: AuthService, private router: Router) {}

  canActivate(): boolean | UrlTree {
    return (this.authService.customerInformation?.noConvergedInfoYet === false || this.authService.customerInformation?.magentaVkfe)
      ? true
      : this.router.parseUrl('/bestellingen/thuis');
  }
}
