import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { UrlService } from 'app/core/services/url.service';
import { Settings2MagentaFeature } from 'app/shared/models/features';
import { MigrationGuard } from './migration.guard';

@Injectable({
  providedIn: 'root'
})
export class SettingsGuard extends MigrationGuard implements CanActivate {

  constructor(
    authService: AuthService,
    settings2MagentaFeature: Settings2MagentaFeature,
    urlService: UrlService
  ) {
    super(authService, settings2MagentaFeature, urlService);
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return super.canActivate(route);
  }
}
